import React, { useState, useRef, useEffect } from 'react';

const EditableMessage = ({ message, onSave, onCancel }) => {
  // Initialize with message.message instead of message.content
  const [editedContent, setEditedContent] = useState('');
  const textareaRef = useRef(null);

  // Initialize the content when component mounts
  useEffect(() => {
    if (message && message.message) {
      // Handle both string and object message formats
      const initialContent = typeof message.message === 'object' 
        ? message.message.content || JSON.stringify(message.message) 
        : message.message;
      
      setEditedContent(initialContent);
    }
  }, [message]);

  // Auto-resize textarea
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [editedContent]);

  const handleChange = (e) => {
    setEditedContent(e.target.value);
  };

  const handleSave = () => {
    if (editedContent.trim()) {
      onSave(editedContent);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSave();
    }
    if (e.key === 'Escape') {
      onCancel();
    }
  };

  return (
    <div className="editable-message">
      <textarea
        ref={textareaRef}
        value={editedContent}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        className="editable-textarea"
        placeholder="Edit your message..."
      />
      <div className="edit-actions">
        <button 
          onClick={onCancel} 
          className="cancel-button"
        >
          Cancel
        </button>
        <button 
          onClick={handleSave} 
          className="send-button"
          disabled={!editedContent.trim()}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default EditableMessage;