import axios from "axios";
import { v4 as uuidv4 } from "uuid";
const babuApi = "https://chain.onepgr.com/babu";
// const babuApi = "http://localhost:4000/babu";

export const getBabuLangchainResponse = async (input_text,session_id) => {
    try {
        let body = {question: input_text, sessionId: session_id}
        const response = await axios.post(
          `${babuApi}/getLangchainResponse`,
          body
        );
        console.log('getLangchainResponse----->',response.data)
        return response.data;
      } catch (error) {
        console.log("Error While getLangchainResponse", error);
      }
  };
  export const updateBabuChatName = async (payload) => {
    try {
        let body =payload
        const response = await axios.post(
          `${babuApi}/updateBabuChatName`,
          body
        );
        console.log('updateBabuChatName----->',response.data)
        return response.data;
      } catch (error) {
        console.log("Error While updateBabuChatName", error);
      }
  };


  export const deleteBabuChatSession = async (payload) => {
    try {
        let body =payload
        const response = await axios.post(
          `${babuApi}/deleteAccountSession`,
          body
        );
        console.log('deleteBabuChatSession----->',response.data)
        return response.data;
      } catch (error) {
        console.log("Error While deleteBabuChatSession", error);
      }
  };

  export const parseEmailContent = (content) => {
    if (typeof content === 'string') {
      const formalSubjectMatch = content.match(/Subject:(.*?)(?=\n)/);
      const formalBodyMatch = content.match(/Body:\n([\s\S]*$)/);
  
      if (formalSubjectMatch && formalBodyMatch) {
        const emailContent = {
          subject: formalSubjectMatch[1].trim(),
          body: formalBodyMatch[1].trim()
        };
        return emailContent;
      }
      const subjectMatch = content.match(/Subject:(.*?)(?=\n)/);
      if (subjectMatch) {
        const parts = content.split('\n');
        const subjectIndex = parts.findIndex(line => line.includes('Subject:'));
        
        if (subjectIndex !== -1) {
          const subject = subjectMatch[1].trim();
          const body = parts.slice(subjectIndex + 1).join('\n').trim();
          const emailContent = {
            subject: subject,
            body: body
          };
          console.log('Parsed Email Content (Simple Format):', emailContent);
          return emailContent;
        }
      }
      const simpleMatch = content.match(/^subject:\s*(.*?)\n([\s\S]*$)/i);
      if (simpleMatch) {
        const emailContent = {
          subject: simpleMatch[1].trim(),
          body: simpleMatch[2].trim()
        };
        console.log('Parsed Email Content (Case Insensitive):', emailContent);
        return emailContent;
      }
    }
    return null;
  };