import React, { useState, useRef, useEffect } from 'react';
import { Send } from 'lucide-react';

const MessageInput = ({ 
  onSendMessage, 
  initialValue = '', 
  disabled = false, 
  enrichmentMode = null, 
  sequenceMode = null, 
  dropSequenceMode = null,
  engageSequenceMode = null
}) => {
  const [input, setInput] = useState('');
  const [isInputExpanded, setIsInputExpanded] = useState(false);
  const inputRef = useRef(null);

  // Add global Enter key listener
  useEffect(() => {
    const handleGlobalKeyDown = (e) => {
      if (e.key === 'Enter' && !e.shiftKey && !disabled && 
         (enrichmentMode || sequenceMode || dropSequenceMode || engageSequenceMode)) {
        e.preventDefault();
        handleSubmit(e);
      }
    };

    window.addEventListener('keydown', handleGlobalKeyDown);
    return () => window.removeEventListener('keydown', handleGlobalKeyDown);
  }, [disabled, enrichmentMode, sequenceMode, dropSequenceMode, engageSequenceMode]);

  useEffect(() => {
    setInput(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (enrichmentMode === 'yesno' || enrichmentMode === 'type' ||
        sequenceMode === 'yesno' || sequenceMode === 'type' ||
        dropSequenceMode === 'yesno' || dropSequenceMode === 'type' ||
        engageSequenceMode === 'yesno' || engageSequenceMode === 'type') {
      setInput('');
    }
  }, [enrichmentMode, sequenceMode, dropSequenceMode, engageSequenceMode]);

  const getDefaultOption = () => {
    // Get all message elements
    const messages = document.querySelectorAll('.message-area .ai-message');
    const lastMessage = Array.from(messages).pop()?.textContent?.toLowerCase() || '';
    console.log('Last message:', lastMessage);

    // For yes/no questions in any mode
    if (enrichmentMode === 'yesno' || 
        sequenceMode === 'yesno' || 
        dropSequenceMode === 'yesno' ||
        engageSequenceMode === 'yesno') {
      return 'yes';
    }

    // For type questions in enrichment mode
    if (enrichmentMode === 'type') {
      if (lastMessage.includes('email') && lastMessage.includes('phone')) {
        return 'email';
      }
    }

    // For type questions in sequence mode
    if (sequenceMode === 'type') {
      if (lastMessage.includes('new sequence') || lastMessage.includes('existing sequence')) {
        return 'new sequence';
      }
    }

    // For type questions in drop sequence mode
    if (dropSequenceMode === 'type') {
      if (lastMessage.includes('drop') || lastMessage.includes('execute')) {
        return 'drop';
      }
    }

    // For type questions in engage sequence mode
    if (engageSequenceMode === 'type') {
      if (lastMessage.includes('engage') || lastMessage.includes('sequence')) {
        return 'engage';
      }
    }

    return '';
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (disabled) return;

    let messageToSend = input.trim();

    // If no input but we're in any mode that accepts default options
    if (!messageToSend && (
      enrichmentMode || 
      sequenceMode || 
      dropSequenceMode || 
      engageSequenceMode
    )) {
      messageToSend = getDefaultOption();
    }

    // Only proceed if we have something to send or are in a mode that accepts defaults
    if (messageToSend || 
        enrichmentMode || 
        sequenceMode || 
        dropSequenceMode ||
        engageSequenceMode) {
      onSendMessage(messageToSend);
      setInput('');
      if (inputRef.current) {
        inputRef.current.style.height = 'auto';
        setIsInputExpanded(false);
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault(); // Prevent the Enter from adding a new line
      e.stopPropagation(); // Stop the event from triggering the global listener
      handleSubmit(e);
    }
  };

  const handleInputChange = (e) => {
    setInput(e.target.value);
    if (inputRef.current) {
      inputRef.current.style.height = 'auto';
      const newHeight = Math.min(inputRef.current.scrollHeight, 200);
      inputRef.current.style.height = `${newHeight}px`;
      setIsInputExpanded(newHeight > 40);
    }
  };

  const getPlaceholder = () => {
    if (disabled) return "Please wait...";
    if (enrichmentMode === 'yesno' || 
        sequenceMode === 'yesno' || 
        dropSequenceMode === 'yesno' ||
        engageSequenceMode === 'yesno') {
      return "Press Enter for 'yes' or type 'no'";
    }
    if (enrichmentMode === 'type' || 
        sequenceMode === 'type' || 
        dropSequenceMode === 'type' ||
        engageSequenceMode === 'type') {
      const defaultOption = getDefaultOption();
      return defaultOption ? `Press Enter for '${defaultOption}' or type another option` : "Type your choice";
    }
    return "Type a message...";
  };

  return (
    <form 
      onSubmit={handleSubmit} 
      className="chat-form"
      style={{ display: 'flex', gap: '8px' }}
    >
      <textarea
        ref={inputRef}
        value={input}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        placeholder={getPlaceholder()}
        className={`chat-input ${isInputExpanded ? 'expanded' : ''} ${disabled ? 'disabled' : ''}`}
        rows={1}
        disabled={disabled}
        style={{ flex: 1 }}
      />
      <button 
        type="submit" 
        className="chat-button" 
        disabled={disabled}
        style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
      >
        <Send size={20} />
      </button>
    </form>
  );
};

export default MessageInput;