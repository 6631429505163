
import { OpenAI } from '@langchain/openai';
import { PromptTemplate } from '@langchain/core/prompts';
import { LLMChain } from 'langchain/chains';
import { ConversationChain } from 'langchain/chains';
import { BufferMemory } from 'langchain/memory';

// Initialize OpenAI model
const model = new OpenAI({ 
  openAIApiKey: process.env.REACT_APP_OPENAI_API_KEY,
  temperature: 0.7,
});

// Helper function to estimate tokens
function estimateTokens(text) {
  return text.split(/\s+/).length;
}

// Function to summarize a person's data
function summarizePerson(person) {
  return {
    name: person.name,
    title: person.title,
    company: person.organization?.name || 'N/A',
    location: `${person.city}, ${person.state}, ${person.country}`,
    seniority: person.seniority,
    departments: person.departments.join(', ')
  };
}

// Function to chunk array with a maximum token limit
function chunkArray(array, maxTokens) {
  const chunks = [];
  let currentChunk = [];
  let currentTokens = 0;

  for (const item of array) {
    const itemTokens = estimateTokens(JSON.stringify(item));
    if (currentTokens + itemTokens > maxTokens && currentChunk.length > 0) {
      chunks.push(currentChunk);
      currentChunk = [];
      currentTokens = 0;
    }
    currentChunk.push(item);
    currentTokens += itemTokens;
  }

  if (currentChunk.length > 0) {
    chunks.push(currentChunk);
  }

  return chunks;
}

// Function to analyze a single chunk
async function analyzeChunk(chunk, totalPeople, retryCount = 0) {
  const maxRetries = 3;
  const template = `
    Analyze the following summarized data about {chunkSize} people out of a total dataset of {totalPeople} people:
    {chunkData}

    Generate 2-3 insightful observations about this subset of data. Consider things like:
    1. Common job titles or roles
    2. Companies represented
    3. Geographical distribution
    4. Any notable patterns in seniority or departments

    Remember, this is a subset of the total data. Focus on clear patterns in this group, but avoid making sweeping generalizations about the entire dataset.

    Format your response as a JSON object with an "insights" key containing an array of string observations.
  `;

  const prompt = new PromptTemplate({
    template: template,
    inputVariables: ["chunkSize", "totalPeople", "chunkData"],
  });

  const chain = new LLMChain({ llm: model, prompt: prompt });

  try {
    const response = await chain.call({
      chunkSize: chunk.length,
      totalPeople: totalPeople,
      chunkData: JSON.stringify(chunk, null, 2)
    });

    const content = JSON.parse(response.text);
    return content.insights || [];
  } catch (error) {
    if (error.message.includes("maximum context length") && retryCount < maxRetries) {
      console.warn(`Chunk too large, retrying with smaller chunk. Retry count: ${retryCount + 1}`);
      const smallerChunks = chunkArray(chunk, Math.floor(chunk.length / 2));
      const results = await Promise.all(smallerChunks.map(c => analyzeChunk(c, totalPeople, retryCount + 1)));
      return results.flat();
    } else {
      console.error("Error in analyzeChunk:", error);
      return ["Error: Unable to analyze this chunk of data."];
    }
  }
}

// Main function to analyze found people
export const analyzeFoundPeople = async (peopleData) => {
  try {
    const summarizedPeople = peopleData.map(summarizePerson);
    const chunks = chunkArray(summarizedPeople, 3000); // Lowered token limit for safety

    const chunkInsights = await Promise.all(chunks.map(chunk => analyzeChunk(chunk, peopleData.length)));

    // Flatten the array of arrays and deduplicate insights
    const uniqueInsights = Array.from(new Set(chunkInsights.flat()));

    // Generate overall summary
    const summaryTemplate = `
      Summarize the following insights about a group of {peopleCount} people:
      {insights}

      Provide 3-5 key takeaways that summarize the most important insights.
      Format your response as a JSON object with a "summary" key containing an array of string takeaways.
    `;

    const summaryPrompt = new PromptTemplate({
      template: summaryTemplate,
      inputVariables: ["peopleCount", "insights"],
    });

    const summaryChain = new LLMChain({ llm: model, prompt: summaryPrompt });

    const summaryResponse = await summaryChain.call({
      peopleCount: peopleData.length,
      insights: JSON.stringify(uniqueInsights, null, 2)
    });

    const summaryContent = JSON.parse(summaryResponse.text);
    return summaryContent.summary || ["Unable to generate a summary due to unexpected response format."];

  } catch (error) {
    console.error('Error analyzing people data:', error);
    return ["Unable to generate insights due to an error.  try again or contact support if the problem persists."];
  }
};

// Function to generate enrichment question
export const generateEnrichmentQuestion = async (peopleCount) => {
  try {
    const template = `Generate a friendly, conversational question asking if the user would like to enrich the data for {peopleCount} people that were found. The question should be engaging and encourage the user to respond.`;

    const prompt = new PromptTemplate({
      template: template,
      inputVariables: ["peopleCount"],
    });

    const chain = new LLMChain({ llm: model, prompt: prompt });

    const response = await chain.call({ peopleCount: peopleCount });

    return response.text.trim();
  } catch (error) {
    console.error('Error generating enrichment question:', error);
    return `Would you like to enrich the data for these ${peopleCount} people?`;
  }
};

// Create a conversation chain for more complex interactions
const conversationChain = new ConversationChain({
  llm: model,
  memory: new BufferMemory()
});

// Function to get more detailed analysis or answer specific questions
export const getDetailedAnalysis = async (question, context) => {
  try {
    const response = await conversationChain.call({
      input: `Given this context about our data: ${context}\n\nQuestion: ${question}\n\nPlease provide a detailed answer:`
    });

    return response.response;
  } catch (error) {
    console.error('Error getting detailed analysis:', error);
    return "I'm sorry, I couldn't generate a detailed analysis at this time.  try again later.";
  }
};