import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./ReviewSequenceModal.css";
import { FaAngleLeft, FaAngleRight, FaTimes } from "react-icons/fa";

const ReviewSequenceModal = ({
  contactActionsData,
  toggleSequenceReviewModal,
  executeSequence,
}) => {
  const { sequenceData, allContactActions } = contactActionsData;
  const [currentIndex, setCurrentIndex] = useState(0);
  const selectedContactAction = allContactActions[currentIndex];

  const onNext = () => {
    if (currentIndex < allContactActions.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const onPrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  return (
    <div className="review-sequence-overlay">
      <div className="review-sequence-modal">
        <div className="review-sequence-header">
          <h2>
            Review Message for Sequence:{" "}
            <span className="header-highlight">{sequenceData.name}</span>
          </h2>
          <button
            className="close-btn"
            onClick={() => toggleSequenceReviewModal(false)}
          >
            <FaTimes />
          </button>
        </div>

        <div className="review-sequence-body">
          <div className="review-sequence-navigation">
            <button onClick={onPrev} disabled={currentIndex === 0}>
              <FaAngleLeft />
            </button>
            <span style={{'margin-top':"10px"}}>{currentIndex + 1} of {allContactActions.length}</span>
            <button onClick={onNext} disabled={currentIndex === allContactActions.length - 1}>
              <FaAngleRight />
            </button>
          </div>

          <div className="review-sequence-info">
            <p>
              <strong>Message Type:</strong>{" "}
              <span className="highlight">{selectedContactAction.type}</span>
            </p>
            <p>
              <strong>Subject:</strong>{" "}
              <span className="highlight">{selectedContactAction.msg_subject}</span>
            </p>
            <p>
              <strong>To:</strong> <span className="highlight">{selectedContactAction.receiver_email}</span>
            </p>
            <p>
              <strong>From:</strong>{" "}
              <span className="highlight">{selectedContactAction.sdr_owner}</span>
            </p>
          </div>

          <div className="review-sequence-editor">
            <ReactQuill value={selectedContactAction.msg_body.replace(/\r\n/g, '<br />')} readOnly theme="snow" />
          </div>

          <div className="review-sequence-footer">
            <button
              className="cancel-btn"
              onClick={() => toggleSequenceReviewModal(false)}
            >
              Cancel
            </button>
            <button className="schedule-btn" onClick={executeSequence}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewSequenceModal;
