import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import axios from 'axios';
import { faPlus, faClock, faChevronDown, faCog,faRobot, faSignOutAlt, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { getRecentChats } from '../api/api';
import '../styles/sideBar.css';
import { UserContext } from './context/userContext';
import moment from 'moment';
import { useNavigate, useLocation } from 'react-router-dom';
import { deleteBabuChatSession, updateBabuChatName } from '../api/babuApi';
import { FaPencilAlt, FaTrash } from 'react-icons/fa';


const Sidebar = ({ onLogout, onNewChat, userId, recentChats, activeSessionId, onChatSelect }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [user, setUser] = useState(null);

    const [isAccordionOpen, setIsAccordionOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');
    const [linkInput, setLinkInput] = useState('');
    const [isRecentsOpen, setIsRecentsOpen] = useState(false);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const sessionType = "4"
    const [showAllChats, setShowAllChats] = useState(false);
    const [isProspectAIOpen, setIsProspectAIOpen] = useState(false);
    const [activeDropdown, setActiveDropdown] = useState(null);
    const [editingChatId, setEditingChatId] = useState(null);
    const [editedTitle, setEditedTitle] = useState('');
    const [visibleChats,setVisibleChats] = useState([])

    // useEffect(() => {
    //     if (user && user.userId) {
    //       fetchRecentChats();
    //     }
    //   }, [user]);



    useEffect(() => {
        const userData = JSON.parse(localStorage.getItem('user'));
        setUser(userData);
    }, []);
    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };


    const handleMouseEnter = () => {
        setIsSidebarOpen(true);
    };

    const handleMouseLeave = () => {
        setIsSidebarOpen(false);
    };


    const handleRecentChatClick = (sessionId) => {
        console.log("Recent chat clicked. SessionId:", sessionId);
        console.log("Current location:", location.pathname);
        const newPath = `/chat/${sessionId}`;
        console.log("New path:", newPath);
        if (location.pathname !== newPath) {
            console.log("Navigating to:", newPath);
            navigate(newPath, { replace: true });
        } else {
            console.log("Already on the correct path, not navigating.");
        }
    };
    const toggleRecents = () => {
        setIsRecentsOpen(!isRecentsOpen);
        if (!isRecentsOpen) {
            setShowAllChats(false);
        }
    };
    const toggleShowAllChats = () => setShowAllChats(!showAllChats);
    // let visibleChats = showAllChats ? recentChats : recentChats.slice(0, 5);
    useEffect(() => {
        setVisibleChats(showAllChats ? recentChats : recentChats.slice(0, 5));
    }, [showAllChats, recentChats]);

    const toggleProspectAI = () => setIsProspectAIOpen(!isProspectAIOpen);

    const toggleAccordion = () => setIsAccordionOpen(!isAccordionOpen);

    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
    };

    const handleLinkInputChange = (e) => {
        setLinkInput(e.target.value);
    };

    const handleFileUpload = (e) => {
        // Handle file upload logic here
        console.log('File uploaded:', e.target.files);
    };

    function navigateToRoute(route) {
        navigate(route)
    }



    const extractMainIntent = (content) => {
        if (!content) return 'Untitled Chat';

        const words = content.split(' ');
        const shortIntent = words.slice(0, 5).join(' ');
        return words.length > 5 ? `${shortIntent}...` : shortIntent;
    };

    const formatDate = (date) => {
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const d = new Date(date);
        const day = d.getDate().toString().padStart(2, '0');
        const month = monthNames[d.getMonth()];
        let hours = d.getHours();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        const minutes = d.getMinutes().toString().padStart(2, '0');

        return {
            datePart: `${day} ${month}`,
            timePart: `${hours}:${minutes} ${ampm}`
        };
    };

    const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

    const getAvatarContent = () => {
        if (!user) return '';
        if (user.avatar) return <img src={user.avatar} alt="User avatar" className="user-avatar-img" />;
        return user.name.charAt(0).toUpperCase();
    };

    const handleAccountSettings = () => {
        navigate('/account-settings');
    }
    const handleApplicationTesting  = () =>{
        navigate('/application-testing');
    }

    const toggleDropdownOptions = (event, chatId) => {
        event.stopPropagation();  // Prevent triggering onClick of the chat item
        setActiveDropdown(activeDropdown === chatId ? null : chatId);
    };

    const handleRename = (chatId, currentTitle) => {
        setEditingChatId(chatId);
        setEditedTitle(currentTitle || 'Untitled Chat');
        setActiveDropdown(null);
    };
    
    const handleTitleChange = (e) => {
        setEditedTitle(e.target.value);
    };
    
    const saveTitle = async (chatId) => {
        const updatedChats = visibleChats.map((chat) =>
            chat._id === chatId ? { ...chat, chat_name: editedTitle } : chat
        );
        setVisibleChats(updatedChats);
        await updateBabuChatName({ sessionId: chatId, chat_name: editedTitle });
        setEditingChatId(null);
        setEditedTitle('');
    };
    
    const handleDelete = async (chatId) => {
        // Add Confirmation Modal to delete
        const updatedChats = visibleChats.filter(chat => chat._id !== chatId);
        setVisibleChats(updatedChats);
        await deleteBabuChatSession({ sessionId: chatId });
        console.log('Deleted chat with ID:', chatId);
    };
    
    const handleKeyDown = (e, chatId) => {
        if (e.key === 'Enter') {
            saveTitle(chatId);
        }
    };
    
    return (
        <>
            <div
                className="sidebar-trigger"
                style={{
                    position: 'fixed',
                    left: 0,
                    top: 0,
                    width: '50px',
                    height: '100%',
                    zIndex: 1000
                }}
                onMouseEnter={handleMouseEnter}
            ></div>
             <div
                className={`sidebar ${isSidebarOpen ? 'open' : ''}`}
                onMouseLeave={handleMouseLeave}
            >
                <button className="new-chat-btn" onClick={onNewChat}>
                    <FontAwesomeIcon icon={faPlus} /> Start new chat
                </button>

                {/* Prospect AI Section */}
                <div className="sidebar-section">
                    <h3 onClick={toggleProspectAI} className="accordion-header">
                        <FontAwesomeIcon icon={faRobot} /> Prospect AI
                        <FontAwesomeIcon
                            icon={faChevronRight}
                            className={`accordion-icon ${isProspectAIOpen ? 'open' : ''}`}
                        />
                    </h3>
                    <div className={`accordion-content-main ${isProspectAIOpen ? 'open' : ''}`}>
                        {/* Moved Recents section here */}
                        <div className="sidebar-section">
                            <h3 onClick={toggleRecents} className="accordion-header">
                                <FontAwesomeIcon icon={faClock} /> Recents
                                <FontAwesomeIcon
                                    icon={faChevronRight}
                                    className={`accordion-icon ${isRecentsOpen ? 'open' : ''}`}
                                />
                            </h3>
                            <div className={`accordion-content ${isRecentsOpen ? 'open' : ''} ${showAllChats ? 'show-all' : ''}`}>
                                {recentChats.length > 0 ? (
                                    <>
                                        <ul className="chat-list">
                                        {visibleChats.map((chat) => (
                                                <li
                                                    key={chat._id}
                                                    className={`chat-item ${chat._id === activeSessionId ? 'active' : ''}`}
                                                    onClick={() => onChatSelect(chat._id)}
                                                >
                                                    <div className="chat-content">
                                                        <span className="chat-intent">
                                                            {editingChatId === chat._id ? (
                                                                <input
                                                                    type="text"
                                                                    value={editedTitle}
                                                                    onChange={handleTitleChange}
                                                                    onBlur={() => saveTitle(chat._id)}
                                                                    onKeyDown={(e) => handleKeyDown(e, chat._id)}
                                                                    autoFocus
                                                                />
                                                            ) : (
                                                                chat.chat_name ? chat.chat_name : chat.messages && chat.messages[0]?.data.content || 'Untitled Chat'
                                                            )}
                                                        </span>
                                                        <div className="chat-timestamp">
                                                            <div>{formatDate(chat.messages && chat.messages[0]?.data.time).datePart}</div>
                                                            <div>{formatDate(chat.messages && chat.messages[0]?.data.time).timePart}</div>
                                                        </div>
                                                        <div className="edit-chat-options">
                                                            <span style={{ cursor: 'pointer' }} onClick={(e) => toggleDropdownOptions(e, chat._id)}>⋮</span>
                                                            {activeDropdown === chat._id && (
                                                                <div className="dropdown-menu-chat-options">
                                                                    <button onClick={() => handleRename(chat._id, chat.chat_name ? chat.chat_name : chat.messages && chat.messages[0]?.data.content || 'Untitled Chat')}>
                                                                        <FaPencilAlt style={{ marginRight: '15px' }} /> Rename
                                                                    </button>
                                                                    <button onClick={() => handleDelete(chat._id)} style={{ color: '#f93a37' }}>
                                                                        <FaTrash style={{ marginRight: '15px' }} /> Delete
                                                                    </button>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                        {recentChats.length > 5 && isRecentsOpen && (
                                            <button className="view-all-btn" onClick={toggleShowAllChats}>
                                                {showAllChats ? 'View less ←' : 'View all →'}
                                            </button>
                                        )}
                                    </>
                                ) : (
                                    <p className="no-chats-message">No recent chats to show</p>
                                )}
                            </div>
                        </div>

                        {/* Moved Custom Content section here */}
                        <div className="custom-context-section">
                            <h4 onClick={toggleAccordion}>
                                Custom Content
                                <FontAwesomeIcon 
                                    icon={faChevronRight} 
                                    className={`accordion-icon ${isAccordionOpen ? 'open' : ''}`} 
                                />
                            </h4>
                            <div className={`accordion-content ${isAccordionOpen ? 'open' : ''}`}>
                                <div className="accordion-inner">
                                    <div className="content-selector">
                                        <label htmlFor="content-dropdown">Add content:</label>
                                        <select id="content-dropdown">
                                            <option value="">Select Content Type</option>
                                            <option value="option1">Option 1</option>
                                            <option value="option2">Option 2</option>
                                            <option value="option3">Option 3</option>
                                            <option value="option4">Option 4</option>
                                        </select>
                                    </div>
                                    <div className="radio-options">
                                        <label>
                                            <input
                                                className='radio-input'
                                                type="radio"
                                                value="onepgr"
                                                checked={selectedOption === 'onepgr'}
                                                onChange={handleOptionChange}
                                            />
                                            OnePgr
                                        </label>
                                        <label>
                                            <input
                                                type="radio"
                                                value="upload"
                                                checked={selectedOption === 'upload'}
                                                onChange={handleOptionChange}
                                            />
                                            Upload
                                        </label>
                                        <label>
                                            <input
                                                type="radio"
                                                value="addLink"
                                                checked={selectedOption === 'addLink'}
                                                onChange={handleOptionChange}
                                            />
                                            URL
                                        </label>
                                    </div>
                                    {selectedOption === 'addLink' && (
                                        <div className="link-input">
                                            <input
                                                type="text"
                                                value={linkInput}
                                                onChange={handleLinkInputChange}
                                                placeholder="Enter link"
                                            />
                                            <button className='add-btn-url'>Add</button>
                                        </div>
                                    )}
                                    {selectedOption === 'upload' && (
                                        <div className="file-upload-area">
                                            <input
                                                type="file"
                                                id="file-upload"
                                                onChange={handleFileUpload}
                                                style={{ display: 'none' }}
                                            />
                                            <label htmlFor="file-upload" className="file-upload-label">
                                                Drop your files here
                                            </label>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="custom-context-section">
                    <h4 onClick={() => { navigateToRoute('/ai-campaign') }}>
                        AI Campaign
                    </h4>
                </div>

                <div className="custom-context-section">
                    <h4 onClick={ handleApplicationTesting }>
                        Application-testing
                    </h4>
                </div>

                <div className="sidebar-footer">
                    {user && (
                        <div className="user-info" onClick={toggleDropdown}>
                            <div className="user-avatar">
                                {getAvatarContent()}
                            </div>
                            <div className="user-email">{user.name}</div>
                            <FontAwesomeIcon icon={faChevronDown} className={`dropdown-icon ${isDropdownOpen ? 'open' : ''}`} />
                        </div>
                    )}
                    {isDropdownOpen && (
                        <div className="dropdown-menu">
                            <button onClick={handleAccountSettings}><FontAwesomeIcon icon={faCog} />Account Settings</button>
                            <button onClick={onLogout}><FontAwesomeIcon icon={faSignOutAlt} /> Logout</button>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default Sidebar;