import React, { createContext, useState, useEffect, useCallback } from 'react';
import { OpenAI } from '@langchain/openai';
import { ConversationChain } from 'langchain/chains';
import { BufferMemory } from 'langchain/memory';

export const CombinedContext = createContext();

export const CombinedProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [langChain, setLangChain] = useState(null);


  useEffect(() => {
    // Check for user data in localStorage on initial load
    const storedUser = localStorage.getItem('user');
    console.log("Stored user:", storedUser);
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }

    // Initialize LangChain
    initializeLangChain();
  }, []);

  const loginUser = useCallback((userData) => {
    console.log("Login user data:", userData);
    setUser(userData);
    localStorage.setItem('user', JSON.stringify(userData));
  }, []);

  const initializeLangChain = async () => {
    const model = new OpenAI({ 
      openAIApiKey: process.env.REACT_APP_OPENAI_API_KEY,
      temperature: 0.7,
    });
    const chain = new ConversationChain({ 
      llm: model, 
      memory: new BufferMemory()
    });
    setLangChain(chain);
  };

  const runLangChain = async (input) => {
    if (!langChain) {
      console.error('LangChain not initialized');
      return null;
    }
    try {
      const response = await langChain.call({ input });
      return response.response;
    } catch (error) {
      console.error('Error running LangChain:', error);
      return null;
    }
  };

  return (
    <CombinedContext.Provider value={{ 
      user, 
      setUser, 
      loginUser, 
      runLangChain 
    }}>
      {children}
    </CombinedContext.Provider>
  );
};

export const useCombinedContext = () => React.useContext(CombinedContext);