


import React, { useState, useRef, useContext, useEffect, useMemo, useCallback } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { ChevronDown } from 'lucide-react';
import MessageList from './messgeList';
import MessageInput from './messageInput';
import axios from 'axios';
// import { processUserInput , handleGeneralConversation } from '../utils/langchainProcessor';
import { sendProcessedDataToBackend, enrichPeopleData, testBackendConnection } from '../api/api';
import { analyzeFoundPeople } from './dataAnalysis';
import { useCombinedContext } from './context/userContext';
import '../styles/chatInterface.css';
import Sidebar from './sideBar';
import NavBar from './navBar';
import { processWithLangChain, sendReviewedDataToBackend, createNewChat, fetchChatHistory, sendMessageToChat, getRecentChats, processUserInput, handleGeneralConversation, processEmailRequest, createGenAIPrompt, scrapeLinkedInProfile ,searchCompanies ,WebScraper} from '../api/api';
import ReviewPrompt from './reviewPrompt';
import PeopleTable from './peopleTable';
import { v4 as uuidv4 } from 'uuid';
import { addStepToParticularSequence, createNewSequence, getActionBlockDetails, getAutomatedSequenceList, getAutomatedSequenceListDetails, getContactsFromConactList, getOnepgrContactLists, getProfileAttributes, getSenderAppAccounts, getSequenceLevelActionBlocks } from '../api/onepgrApi';
import moment from 'moment';
import { createAndScheduleContactAction, createSequenceHistoryApi, generateOpenAiResponse } from '../api/meetSessionApi';
import ReviewSequenceModal from './sharedComponents/reviewSequence/ReviewSequenceModal';


const ChatInterface = ({ onLogout }) => {
  const [messages, setMessages] = useState([]);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [reviewData, setReviewData] = useState(null);
  const [editingMessageId, setEditingMessageId] = useState(null);
  const messagesEndRef = useRef(null);
  const messageAreaRef = useRef(null);
  const [peopleData, setPeopleData] = useState(null);
  const [enrichmentMode, setEnrichmentMode] = useState(null);
  // const { user, setUser } = useContext(useCombinedContext);
  const { sessionId } = useParams();
  const navigate = useNavigate();
  const [activeSessionId, setActiveSessionId] = useState(null);
  const context = useCombinedContext();
  const { user, setUser } = context;
  const [showWelcomeMessage, setShowWelcomeMessage] = useState(true);
  const sessionType = "4"
  const location = useLocation();
  const [isEnriched, setIsEnriched] = useState(false);
  const [recentChats, setRecentChats] = useState([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const sidebarRef = useRef(null);
  const sidebarTriggerRef = useRef(null);
  const [senderAppAccounts, setSenderAppAccounts] = useState([]);
  const [emailStep, setEmailStep] = useState([])
  const [sequenceMode, setSequenceMode] = useState(null);
  const [automatedSequenceList, setAutomatedSequenceList] = useState(null);
  const [showExamples, setShowExamples] = useState(true);
  const [promptUserInput,sePromptUserInput] = useState('');
  const [autoScroll, setAutoScroll] = useState(true);
  const [dropSequenceMode, setDropSequenceMode] = useState("");
  const [selectedSequenceList, setSelectedSequenceList] = useState(null);
  const[selectedContactList, setSelectedContactList] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
const [enrichedPeopleData, setEnrichedPeopleData] = useState(null);
const [engageSequenceMode,setEngageSequenceMode] = useState(null);
const currentContactList_idRef = useRef(null);
const [toggleSequenceReview, setToggleSequenceReview] = useState(false);
const [selectedDropTime, setSelectedDropTime] = useState(moment().add(1, 'minute').format('YYYY-MM-DDTHH:mm'));
const scheduledCotactionsRef = useRef(null);

    // New function to check if user is near bottom
    const isNearBottom = useCallback(() => {
      if (!messageAreaRef.current) return true;
      
      const container = messageAreaRef.current;
      const threshold = 100; // pixels from bottom to trigger auto-scroll
      return container.scrollHeight - container.scrollTop - container.clientHeight < threshold;
    }, []);
  
    // Improved scroll to bottom function
    const scrollToBottom = useCallback((behavior = 'smooth') => {
      if (messageAreaRef.current) {
        let scrollHeight = messageAreaRef.current.scrollHeight;
        const scrollEle = document.getElementsByClassName('message-list-container')[0]
      scrollHeight = scrollEle.scrollHeight
      scrollEle.scrollTo({
          top: scrollHeight,
          behavior: behavior
        });
      }
    }, [messages]);
  
    // Handle scroll events to show/hide scroll button and manage auto-scroll
    const handleScroll = useCallback(() => {
      if (!messageAreaRef.current) return;
      
      const isAtBottom = isNearBottom();
      setAutoScroll(isAtBottom);
      setShowScrollButton(!isAtBottom);
    }, [isNearBottom]);
  
    // Add scroll event listener
    useEffect(() => {
      const messageArea = messageAreaRef.current;
      if (messageArea) {
        messageArea.addEventListener('scroll', handleScroll);
        return () => messageArea.removeEventListener('scroll', handleScroll);
      }
    }, [handleScroll]);
  
    // Auto-scroll when messages change or typing status changes
    useEffect(() => {
      if (autoScroll) {
        scrollToBottom(messages.length === 1 ? 'auto' : 'smooth');
      }
    }, [messages, isTyping, autoScroll, scrollToBottom]);

  
  useEffect(() => {
    const initializeChat = async () => {
      if (sessionId) {
        setActiveSessionId(sessionId);
        await loadChatHistory(sessionId);
      } else {
        const lastSessionId = localStorage.getItem('lastSessionId');
        if (lastSessionId) {
          setActiveSessionId(lastSessionId);
          navigate(`/chat/${lastSessionId}`);
          await loadChatHistory(lastSessionId);
        }
      }
      const resp = await getSenderAppAccounts();
      console.log('resp--->', resp)
      if (resp.success == '0' && resp.response) {
        setSenderAppAccounts(resp.response.app_accounts_list)
      }
    };

    initializeChat();
  }, [sessionId, location.pathname]);

  useEffect(() => {
    fetchRecentChats();
  }, [user]);

  const fetchRecentChats = async () => {
    try {
      const chats = await getRecentChats(user.userId, sessionType);
      setRecentChats(chats);
    } catch (error) {
      console.error('Error fetching recent chats:', error);
    }
  };



  const handleExamplePromptClick = (prompt) => {
    handleUserInput(prompt);
   
  };

  useEffect(() => {
    // Show welcome message on page refresh
    setShowWelcomeMessage(true);
    setShowExamples(true);
  }, []);

  const handleSidebarMouseLeave = (e) => {
    // Check if the mouse is moving towards the main content
    if (e.clientX > sidebarRef.current.getBoundingClientRect().right) {
      setIsSidebarOpen(false);
    }
  };

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (!isSidebarOpen) {
        const triggerRect = sidebarTriggerRef.current.getBoundingClientRect();
        if (e.clientX <= triggerRect.right && e.clientY >= triggerRect.top && e.clientY <= triggerRect.bottom) {
          setIsSidebarOpen(true);
        }
      }
    };

    document.addEventListener('mousemove', handleMouseMove);
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, [isSidebarOpen]);



  const loadChatHistory = async (chatId) => {
    try {
      const history = await fetchChatHistory(chatId, user.userId);
      if (Array.isArray(history) && history.length > 0) {
        setMessages(history);
      } else {
        console.log('No chat history found or empty history returned');
        setMessages([]);
      }
    } catch (error) {
      console.error('Error loading chat history:', error);
      // Optionally, you can set an error state or show a notification to the user
      setMessages([]);
    }
  };

  const handleNewChat = async () => {
    try {
      const newSessionId = await createNewChat(user.userId, sessionType);
      setActiveSessionId(newSessionId);
      localStorage.setItem('lastSessionId', newSessionId);
      navigate(`/chat/${newSessionId}`);
      setMessages([]);
      setShowWelcomeMessage(true);

    } catch (error) {
      console.error('Error creating new chat:', error);
    }
  };
  

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  const handleLogout = () => {
    setUser(null);
    onLogout();
  };



  useEffect(() => {
    testBackendConnection()
      .then(response => console.log('Backend connection successful:', response))
      .catch(error => console.error('Backend connection failed:', error));
  }, []);

  


  const handleUserInput = async (input) => {

    const trimmedInput = input.trim();
    
    let newUserMessage
    if (trimmedInput) {
      setAutoScroll(true);
      sePromptUserInput(trimmedInput)
      newUserMessage = { id: uuidv4(), type: 'human', message: trimmedInput, time: new Date().toISOString() };
      setMessages(prev => [...prev, newUserMessage]);
      setIsTyping(true);
      
      try {
        let currentSessionId = activeSessionId;
        if (!currentSessionId) {
          currentSessionId = await createNewChat(user.userId, sessionType);
          setActiveSessionId(currentSessionId);
          localStorage.setItem('lastSessionId', currentSessionId);
          navigate(`/chat/${currentSessionId}`);
        }

        if (enrichmentMode === 'yesno') {
          await handleEnrichmentResponse(trimmedInput,newUserMessage);
        } else if (enrichmentMode === 'type') {
          await handleEnrichmentResponse(trimmedInput,newUserMessage);
        } else if (sequenceMode === 'yesno') {
          await handleSequenceCreation(trimmedInput, newUserMessage)
        } else if (sequenceMode === 'type') {
          await handleSequenceCreation(trimmedInput, newUserMessage)
        } else if (dropSequenceMode === 'yesno') {
          await handleDropSequence(trimmedInput, newUserMessage)
        } else if (dropSequenceMode === 'type') {
          await handleDropSequence(trimmedInput, newUserMessage)
        } else if (engageSequenceMode === 'yesno') {
          await handleEngageSequenceForLatestContactsList(trimmedInput, newUserMessage)
        } else if (engageSequenceMode === 'createEmial/createSequence') {
          await handleEngageSequenceForLatestContactsList(trimmedInput, newUserMessage)
        } else {
          const processedData = await processUserInput(trimmedInput, currentSessionId);

          if (processedData.needsReview) {
            setReviewData(processedData);
          } else {
            await handleProcessedData(processedData, currentSessionId, trimmedInput, newUserMessage);
          }
        }
      } catch (error) {
        console.error('Error processing input:', error);
        const errorMessage = {
          id: uuidv4(),
          type: 'ai',
          message: 'Error processing your request.',
          time: new Date().toISOString()
        };
        setMessages(prev => [...prev, errorMessage]);
       
      } finally {
        setIsTyping(false);
      }
    }
  };

 

  const handleSendMessage = async (input, sessionId) => {
    const trimmedInput = input.trim();
    if (trimmedInput) {
      const newUserMessage = { id: uuidv4(), type: 'human', message: trimmedInput, time: new Date().toISOString() };

      setMessages(prev => [...prev, newUserMessage]);

      setIsTyping(true);
    
      try {
        setAutoScroll(true);
        // Process the user input
        const processedData = await processUserInput(trimmedInput, sessionId);
        setActiveSessionId(processedData.sessionId);
        if (processedData.needsReview) {
          setReviewData(processedData);

          // Don't proceed further, wait for user review
        } else {
          // Only proceed if no review is needed
          await handleProcessedData(processedData, sessionId, trimmedInput);
        }
      } catch (error) {
        console.error('Error processing prompt:', error);
        const errorMessage = {
          id: Date.now(),
          type: 'ai',
          message: 'Error processing your request.',
          time: new Date().toISOString()
        };
        setMessages(prev => [...prev, errorMessage]);
   
      } finally {
        setIsTyping(false);
      }
    }
  };

  const handleReviewedData = async (reviewedData, sessionId) => {
    setIsTyping(true);
    try {
      const result = await sendReviewedDataToBackend(reviewedData);
      await handleProcessedData(result, sessionId, reviewedData.input);
    } catch (error) {
      console.error('Error processing reviewed data:', error);
      const errorMessage = {
        id: Date.now(),
        type: 'ai',
        message: 'Error processing your request after review.',
        time: new Date().toISOString()
      };
      setMessages(prev => [...prev, errorMessage]);
    } finally {
      setIsTyping(false);
      setReviewData(null);
    }
  };

  const handleProcessedData = async (processedData, sessionId, userInput,) => {
    console.log(userInput)
    let aiResponse;

    if (processedData.type === 'general') {
      aiResponse = processedData.content;
    } else {
      const result = await sendProcessedDataToBackend(processedData);
      if (result.data && result.data.found_people) {
        await handleFindPeople(result.data, sessionId, userInput);
        return;
      } else {
        aiResponse = processedData.content || 'Processed the request, but no specific action was taken.';
      }
    }

    const newAiMessage = { id: uuidv4(), type: 'ai', message: aiResponse, time: new Date().toISOString() };
    setMessages(prev => [...prev, newAiMessage]);

    // Insert both user and bot messages together
    // await sendMessageToChat(sessionId, userInput, aiResponse, user.userId, sessionType);

    fetchRecentChats();
  };
  const handleFindPeople = async (data, sessionId, userInput) => {
    try {
      console.log("people data---->", data);
      
      if (!data) {
        throw new Error('No data received');
      }
  
      let newMessages = [];
  
      // Handle case where no companies were found
      if (data.message === 'No companies found for the given criteria') {
        const noDataMessage = {
          id: uuidv4(),
          type: 'ai',
          message: data.message
        };
        newMessages.push(noDataMessage);
        setPeopleData([]);
        
        await sendMessageToChat(sessionId, userInput, noDataMessage.message, user.userId, sessionType);
        setMessages(prev => [...prev, noDataMessage]);
        return;
      }
  
      const foundPeople = data.found_people?.people || [];
      const companies = data.companies || [];
      console.log("found people-->", foundPeople);
  
      if (Array.isArray(foundPeople) && foundPeople.length === 0) {
        // Format company information
        let companyMessage = '';
        if (companies.length > 0) {
          companyMessage = `Found ${companies.length} companies:\n\n`;
          companies.forEach((company, index) => {
            companyMessage += `${index + 1}. ${company.name}\n`;
            if (company.website_url) {
              companyMessage += `   Website: ${company.website_url}\n`;
            }
            if (company.linkedin_url) {
              companyMessage += `   LinkedIn: ${company.linkedin_url}\n`;
            }
            companyMessage += '\n';
          });
          companyMessage += 'No matching people found in these companies based on your criteria.';
        } else {
          companyMessage = 'No matching people found.';
        }

        const noDataMessage = {
          id: uuidv4(),
          type: 'ai',
          message: companyMessage
        };
        newMessages.push(noDataMessage);
        
        setPeopleData([]);
      } else if (typeof foundPeople === 'string') {
        const stringResponseMessage = {
          id: uuidv4(),
          type: 'ai',
          message: foundPeople
        };
        newMessages.push(stringResponseMessage);
        setPeopleData([]);
      } else if (Array.isArray(foundPeople)) {
        setPeopleData(foundPeople);
        setIsEnriched(false);
        
        try {
          const generatedInsights = await analyzeFoundPeople(foundPeople);
          
          newMessages = [
            { 
              id: uuidv4(), 
              type: 'ai', 
              message: { insights: generatedInsights }
            },
            { 
              id: uuidv4(), 
              type: 'ai', 
              message: { peopleData: foundPeople }
            },
            { 
              id: uuidv4(), 
              type: 'ai', 
              message: 'Select contact(s) to enrich'
            }
          ];
        } catch (analysisError) {
          console.error('Error analyzing people:', analysisError);
          throw new Error(`Failed to analyze people data: ${analysisError.message}`);
        }
      } else {
        throw new Error('Invalid data structure received');
      }
  
      // Send messages to chat and update state
      if (newMessages.length > 0) {
        if (newMessages.length === 1) {
          await sendMessageToChat(sessionId, promptUserInput, newMessages[0].message, user.userId, sessionType);
        } else {
          await sendMessageToChat(sessionId, promptUserInput, JSON.stringify(newMessages[0].message), user.userId, sessionType);
          await sendMessageToChat(sessionId, '', JSON.stringify(newMessages[1].message), user.userId, sessionType);
          await sendMessageToChat(sessionId, '', JSON.stringify(newMessages[2].message), user.userId, sessionType);
        }
        
        setMessages(prev => [...prev, ...newMessages]);
      }
    } catch (error) {
      console.error('Error handling found people:', error);
      const errorMessage = {
        id: uuidv4(),
        type: 'ai',
        message: `Error processing results: ${error.message}`
      };
      await sendMessageToChat(sessionId, userInput, errorMessage.message, user.userId, sessionType);
      setMessages(prev => [...prev, errorMessage]);
    }
};
  const handleLinkedInScrapeData = async (data, sessionId) => {
    console.log('LinkedIn scrape data:', data);
    try {
      const { linkedin_post_data } = data;
      if (!linkedin_post_data) {
        throw new Error('Invalid LinkedIn post data structure');
      }

      const Comments = linkedin_post_data.comments?.resultObject?.resultObject
        ? JSON.parse(linkedin_post_data.comments.resultObject.resultObject)
        : [];
      const Likes = linkedin_post_data.likes?.resultObject?.resultObject
        ? JSON.parse(linkedin_post_data.likes.resultObject.resultObject)
        : [];

      // Combine likes and comments, removing duplicates
      const combinedData = [...Comments, ...Likes];

      const uniqueData = Array.from(new Set(combinedData.map(item => item.profileLink)))
        .map(profileLink => combinedData.find(item => item.profileLink === profileLink))
        .filter(Boolean); // Remove any undefined entries

      // Extract post URL for insights
      const postUrl = uniqueData[0]?.postUrl || "Post URL not available";

      // Generate insights
      const insights = `
        Post URL: ${postUrl}
        Total interactions: ${uniqueData.length}
        Comments: ${Comments.length}
        Likes: ${Likes.length}
        ${getMostCommonReaction(uniqueData)}
      `;

      let newMessages = [
        { id: uuidv4(), type: 'ai', message: { insights: insights } },
        { id: uuidv4(), type: 'ai', message: { linkedInData: uniqueData } },
        {
          id: uuidv4(),
          type: 'ai',
          message: `Select contact(s) to enrich`
        }
      ];

      for (let message of newMessages) {
        await sendMessageToChat(sessionId, null, JSON.stringify(message.message), user.userId, sessionType);
      }

      setMessages(prev => [...prev, ...newMessages]);
    } catch (error) {
      console.error('Error handling LinkedIn scrape data:', error);
      const errorMessage = { id: uuidv4(), type: 'ai', message: `Error handling LinkedIn scrape data: ${error.message}` };
      await sendMessageToChat(sessionId, null, errorMessage.message, user.userId, sessionType);
      setMessages(prev => [...prev, errorMessage]);
    }
  };

  const getMostCommonReaction = (data) => {
    if (!data || data.length === 0) {
      return "Most common reaction: No reactions available";
    }

    const reactionCounts = data.reduce((acc, item) => {
      if (item.reactionType) {
        acc[item.reactionType] = (acc[item.reactionType] || 0) + 1;
      }
      return acc;
    }, {});

    if (Object.keys(reactionCounts).length === 0) {
      return "Most common reaction: No reactions available";
    }

    const [mostCommonReaction, count] = Object.entries(reactionCounts)
      .sort((a, b) => b[1] - a[1])[0];

    return `Most common reaction: ${mostCommonReaction} (${count} times)`;
  };


  const getLinkedInCookie = async (userId) => {
    const url = 'https://onepgr.com/users/get_profile_attributes?onepgr_apicall=1&xhr_flag=1';
    const body = new FormData();
    body.append('id', userId);
    body.append('public_view','1');

    try {
      const response = await axios.post(url, body);
      const parsedResponse = response.data;
      console.log("API Response:", parsedResponse);

      if (parsedResponse && parsedResponse.response) {
        const userDetails = JSON.parse(parsedResponse.response);
        if (userDetails.api_keys) {
          const apiKeys = JSON.parse(userDetails.api_keys);
          if (apiKeys.linkedIn && apiKeys.linkedIn.cookie) {
            console.log('LinkedIn Cookie:', apiKeys.linkedIn.cookie);
            return apiKeys.linkedIn.cookie;
          }
        }
      }
      throw new Error('LinkedIn cookie not found in the response');
    } catch (error) {
      console.error('Error fetching LinkedIn cookie:', error);
      throw error;
    }
  };

  const handleReviewAction = async (action, data) => {
    console.log('Handling review action:', action, data);
    if (action === 'process') {
      setIsTyping(true);
      try {
        let dataWithUserId = {
          ...data,
          userId: user.userId
        };

        if (data.intents && Array.isArray(data.intents)) {
          const linkedInIntent = data.intents.find(intent =>
            intent.type === 'LinkedIn post scraper' || intent.type === 'LinkedIn profile scraper'
          );
          if (linkedInIntent) {
            const linkedinCookie = await getLinkedInCookie(user.userId);
            console.log('LinkedIn Cookie:', linkedinCookie);
            dataWithUserId = {
              ...dataWithUserId,
              sessionCookie: linkedinCookie,
            };
          }
        }
        const intent = data.intents[0];
        if(intent.type === 'create sequence'){
          await handleCreateSequenceAgent(intent,data.input);
        }else if(intent.type === 'sequence schedule') {
          await handleSequenceExecution(intent,data.input);
         } else {
        let result;
        switch (intent.type) { 
          case 'create email':
            result = await processEmailRequest(
              intent.editedInfo.recipient || '',
              intent.editedInfo.topic || '',
              intent.editedInfo.emailType || '',
              activeSessionId,
              data.input
            );
            break;
          case 'create gen ai prompt':
            result = await createGenAIPrompt(
              intent.editedInfo.task || '',
              intent.editedInfo.aspect || '',
              activeSessionId,
              data.input
            );
            break;
            case 'Company Finder':
              // Extract company type and location from editedInfo
              const searchParams = {
                keywords: intent.editedInfo?.companyType || '',
                location: intent.editedInfo?.location || '',
                page: 1,
                perPage: 10,
                userId: user.userId
              };
              
              console.log('Searching companies with:', searchParams);
              result = await searchCompanies(searchParams);
              break;
          case 'LinkedIn profile scraper':
            result = await scrapeLinkedInProfile(
              intent.editedInfo.profileUrl || '',
              dataWithUserId.sessionCookie
            );
            break;
            case 'Web Scraper':
              result = await WebScraper(
                intent.editedInfo.data|| '',
              );
              break;

          default:
            result = await sendReviewedDataToBackend(dataWithUserId);
        }

        console.log('Result from processing:', result);
        if (result.data && result.data.linkedin_profile_data) {
          await handleLinkedInProfileScrapeData(result.data, activeSessionId,data.input);
        } else if (result.data && result.data.linkedin_post_data) {
          await handleLinkedInScrapeData(result.data, activeSessionId);
        } else if (result.data && result.data.found_people) {
          await handleFindPeople(result.data, activeSessionId);
        } else if (result.email_content) {
          console.log('result.email_content--->', result.email_content)
          await handleEmailProcessing(result.email_content, activeSessionId);
        } else if (result.gen_ai_content) {
          await handleGenAIPromptProcessing(result.gen_ai_content, activeSessionId);
        }else if (result.data && Array.isArray(result.data)) {  // Check for web scraper data
          await handleWebScraperData(result.data, activeSessionId, data.input);
      } else if (result.content) {
          const aiMessage = {
            id: uuidv4(),
            type: 'ai',
            message: result.content,
            messageType: 'general',
            time: new Date().toISOString()
          };
          setMessages(prev => [...prev, aiMessage]);
          await sendMessageToChat(activeSessionId, data.input, JSON.stringify(aiMessage), user.userId, sessionType);
        } else {
          throw new Error('Unexpected response structure from backend');
        }
      }
      } catch (error) {
        console.error('Error processing reviewed data:', error);
        const errorMessage = {
          id: Date.now(),
          type: 'ai',
          message: `Error processing your request after review: ${error.message}`,
          time: new Date().toISOString()
        };
        setMessages(prev => [...prev, errorMessage]);
      } finally {
        setIsTyping(false);
        setReviewData(null);
      }
    } else if (action === 'edit') {
      const lastUserMessage = messages[messages.length - 1];
      setEditingMessageId(lastUserMessage.id);
    } else if (action === 'general') {
      setReviewData(null);
      await handleGeneralPrompt(data.input || data, true);
    }
  };

  

  const handleEmailProcessing = async (data, sessionId, userInput) => {
    console.log('data----->', data)
    try {
      const emailContent = data;
      setEmailStep([{
        subject: emailContent.subject,
        msg_body: emailContent.body,
        time_offset: '0',
        isgenEmail: false
      }])
      let newMessages = [];
      if (!emailContent) {
        const noDataMessage = {
          id: uuidv4(),
          type: 'ai',
          message: 'No email content generated.'
        };
        newMessages.push(noDataMessage);
        await sendMessageToChat(sessionId,'', newMessages[0].message, user.userId, sessionType);
      } else {
        const emailMessage = {
          id: uuidv4(),
          type: 'ai',
          message: { emailData: emailContent }
        };
        newMessages.push(emailMessage);
        // await sendMessageToChat(sessionId, promptUserInput,emailMessage.message, user.userId, sessionType);

        // Add a prompt for further action
        // newMessages.push({
        //   id: uuidv4(),
        //   type: 'ai',
        //   content: 'Would you like to edit this email or send it as is? (Edit/Send)'
        // });
        newMessages.push({
          id: uuidv4(),
          type: 'ai',
          message: 'Would you like to add this step to sequence?  (Yes/No)'
        });
        // await sendMessageToChat(sessionId, '', 'Would you like to add this step to sequence?  (Yes/No)', user.userId, sessionType);
        setSequenceMode('yesno');
      }


      // Send messages to chat and update state
      // for (let message of newMessages) {
      //   await sendMessageToChat(sessionId, userInput, JSON.stringify(message.message), user.userId, sessionType);
      // }
     
    
      

      setMessages(prev => [...prev, ...newMessages]);
    } catch (error) {
      console.error('Error handling email processing:', error);
      const errorMessage = { id: uuidv4(), type: 'ai', message: `Error handling email processing: ${error.message}` };
      await sendMessageToChat(sessionId, userInput, errorMessage.message, user.userId, sessionType);
      setMessages(prev => [...prev, errorMessage]);
    }
  };

  const handleGenAIPromptProcessing = async (data, sessionId, userInput) => {
    try {
      setEmailStep([{
        subject: data.subject,
        msg_body: data.body,
        time_offset: '0',
        isgenEmail: true
      }])
      const promptContent = data;
      let newMessages = [];

      if (!promptContent) {
        const noDataMessage = {
          id: uuidv4(),
          type: 'ai',
          message: 'No gen AI prompt generated.'
        };
        newMessages.push(noDataMessage);
      } else {
        const promptMessage = {
          id: uuidv4(),
          type: 'ai',
          message: { genAIPromptData: promptContent }
        };
        newMessages.push(promptMessage);

        // Add a prompt for further action
        // newMessages.push({
        //   id: uuidv4(),
        //   type: 'ai',
        //   content: 'Would you like to edit this gen AI prompt or use it as is? (Edit/Use)'
        // });
        newMessages.push({
          id: uuidv4(),
          type: 'ai',
          message: 'Would you like to add this step to sequence?  (Yes/No)'
        });
        setSequenceMode('yesno');
      }

      // Send messages to chat and update state
      for (let message of newMessages) {
        await sendMessageToChat(sessionId, userInput, JSON.stringify(message.message), user.userId, sessionType);
      }

      setMessages(prev => [...prev, ...newMessages]);
    } catch (error) {
      console.error('Error handling gen AI prompt processing:', error);
      const errorMessage = { id: uuidv4(), type: 'ai', message: `Error handling gen AI prompt processing: ${error.message}` };
      // await sendMessageToChat(sessionId, userInput, errorMessage.content, user.userId, sessionType);
      setMessages(prev => [...prev, errorMessage]);
    }
  };

  const handleWebScraperData = async (data, sessionId, userInput) => {
    console.log('Web Scraper data:', data);

   
    try {
        let newMessages = [];
        
        if (!data || data.length === 0) {
            const noDataMessage = {
                id: uuidv4(),
                type: 'ai',
                message: 'No results found from web scraping.'
            };
            newMessages.push(noDataMessage);
            await sendMessageToChat(sessionId, '', noDataMessage.message, user.userId, sessionType);
        } else {
            // Format the scraped results into a readable message
            const formattedResults = data.map((item, index) => {
              return `${index + 1}. ${item.title}\n` +
                     `   Link: ${item.link}\n` +
                     `   ${item.snippet}\n` +
                     `   ${item.date ? `Date: ${item.date}\n` : ''}`;
          }).join('\n\n');

            // Create message with formatted results
            const resultsWebMessage = {
                id: uuidv4(),
                type: 'ai',
                message:{webData: formattedResults}
            };
            setMessages(prev => [...prev, resultsWebMessage]);
            await sendMessageToChat(sessionId, userInput, JSON.stringify(resultsWebMessage.message), user.userId, sessionType);
        }
       
  
    } catch (error) {
        console.error('Error handling web scraper data:', error);
        const errorMessage = {
            id: uuidv4(),
            type: 'ai',
            message: `Error processing web scraper results: ${error.message}`
        };
        await sendMessageToChat(sessionId, userInput, errorMessage.message, user.userId, sessionType);
        setMessages(prev => [...prev, errorMessage]);
    }
};

  

  const handleLinkedInProfileScrapeData = async (data, sessionId, input) => {
    try {
      console.log('Handling LinkedIn profile scrape data:', data);

      const profileData = data.linkedin_profile_data;

      // Create a formatted message with the scraped profile data
      const profilePostData = `
  LinkedIn Profile Information:
  ----------------------------
  Name: ${profileData.fullName || 'N/A'}
  Headline: ${profileData.headline || 'N/A'}
  Location: ${profileData.location || 'N/A'}
  Current Position: ${profileData.currentPosition || 'N/A'}
  Company: ${profileData.company || 'N/A'}
  Industry: ${profileData.industry || 'N/A'}
  Education: ${profileData.education ? profileData.education.join(', ') : 'N/A'}
  Skills: ${profileData.skills ? profileData.skills.join(', ') : 'N/A'}
  Connections: ${profileData.connections || 'N/A'}
  About: ${profileData.about || 'N/A'}
      `;

      // Create an AI message with the formatted profile data
      const aiMessage = {
        id: uuidv4(),
        type: 'ai',
        message: profilePostData,
        messageType: 'linkedin_profile',
        time: new Date().toISOString()
      };

      // Update the messages state with the new AI message
      setMessages(prev => [...prev, aiMessage]);

      // Send the AI message to the chat
      await sendMessageToChat(sessionId, input, JSON.stringify(aiMessage), user.userId, sessionType);

    } catch (error) {
      console.error('Error handling LinkedIn profile scrape data:', error);

      // Create an error message
      const errorMessage = {
        id: uuidv4(),
        type: 'human',
        message: `Error processing LinkedIn profile data: ${error.message}`,
        time: new Date().toISOString()
      };

      // Update the messages state with the error message
      setMessages(prev => [...prev, errorMessage]);

      // Send the error message to the chat
      // await sendMessageToChat(sessionId, null, JSON.stringify(errorMessage), user.userId, sessionType);
    }
  };

  const handleGeneralPrompt = async (input, isFromReview = false) => {
    setIsTyping(true);
    try {
      let currentSessionId = activeSessionId;
      if (!currentSessionId) {
        currentSessionId = await createNewChat(user.userId, sessionType);
        setActiveSessionId(currentSessionId);
        navigate(`/chat/${currentSessionId}`, { replace: true });
      }
  
      // Only add the user message to the state if it's not from a review action
      if (!isFromReview) {
        const userMessage = {
          id: uuidv4(),
          type: 'human',
          message: input,
          time: new Date().toISOString()
        };
        setMessages(prev => [...prev, userMessage]);
      }
  
      const response = await handleGeneralConversation(input, currentSessionId);
  
      if (response.content) {
        const aiMessage = {
          id: uuidv4(),
          type: 'ai',
          message: response.content.content || response.content,
          time: new Date().toISOString()
        };
  
        // Check if the message contains a subject
        const hasSubject = checkForSubject(aiMessage.message);
  
        // Create an array of new messages to add
        const newMessages = [aiMessage];
  
        // If subject is found, add the follow-up question
        if (hasSubject) {
          newMessages.push({
            id: uuidv4(),
            type: 'ai',
            message: 'Would you like to add this step to sequence? (Yes/No)',
            time: new Date().toISOString()
          });
          setSequenceMode('yesno');
        }
  
        // Add all new messages to the state
        setMessages(prev => [...prev, ...newMessages]);
  
        // Refresh the recent chats list
        fetchRecentChats();
      } else {
        throw new Error('No content in response');
      }
    } catch (error) {
      console.error('Error processing general prompt:', error);
      setMessages(prev => [...prev, {
        id: uuidv4(),
        type: 'ai',
        message: `Error processing your request: ${error.message}`,
        time: new Date().toISOString()
      }]);
    } finally {
      setIsTyping(false);
      setReviewData(null);
    }
  };
  
  // Helper function to check if message contains a subject
  const checkForSubject = (message) => {
    try {
      // Case 1: If message is a string that can be parsed as JSON
      if (typeof message === 'string') {
        try {
          const parsedMessage = JSON.parse(message);
          // Check if parsed message has emailData with subject
          if (parsedMessage.emailData?.subject) {
            return true;
          }
          // Check if parsed message has content with subject
          if (parsedMessage.content?.includes('Subject:')) {
            return true;
          }
        } catch (e) {
          // If not JSON, check if string contains Subject:
          if (message.includes('Subject:')) {
            return true;
          }
        }
      }
      
      // Case 2: If message is already an object
      if (typeof message === 'object') {
        // Check direct emailData
        if (message.emailData?.subject) {
          return true;
        }
        // Check content property
        if (message.content?.includes('Subject:')) {
          return true;
        }
        // Check direct content for Subject:
        if (message.message?.includes('Subject:')) {
          return true;               
        }
      }
  
      return false;
    } catch (error) {
      console.error('Error checking for subject:', error);
      return false;
    }
  };

  const handleTriggerEnrichment = (selectedContacts) => {
    setPeopleData(selectedContacts); // Store selected contacts
    setEnrichmentMode('type'); // Set mode directly to 'type' to skip yes/no
    
    const questionMessage = {
      id: uuidv4(),
      type: 'ai',
      message: "How would you like to enrich the data? (email, phone, or both.)",
      time: new Date().toISOString()
    };
    
    setMessages(prev => [...prev, questionMessage]);
    sendMessageToChat(activeSessionId, '', questionMessage.message, user.userId, sessionType);
  };
  
  const handleEnrichmentResponse = async (input,userMessage) => {
    const lowerInput = input.toLowerCase().trim();
    
    // Only create and send the user message once
    // const userMessage = { 
    //     id: uuidv4(), 
    //     type: 'user', 
    //     content: input, 
    //     time: new Date().toISOString() 
    // };
    
    // Batch the state updates to avoid race conditions
    let aiResponse = '';

    if (enrichmentMode === 'yesno') {
        if (lowerInput === 'yes') {
            aiResponse = "How would you like to enrich the data? Options are: email, phone, or both.";
            setEnrichmentMode('type');
        } else if (lowerInput === 'no') {
            aiResponse = 'Alright, no enrichment will be performed.';
            setEnrichmentMode(null);
        } else {
            aiResponse = "I'm sorry, I didn't understand that. answer with 'yes' or 'no'.";
        }
    } else if (enrichmentMode === 'type') {
        if (['email', 'phone', 'both'].includes(lowerInput)) {
            // aiResponse = `Starting enrichment process for ${lowerInput}...`;
            await performEnrichment(lowerInput,input);
            setEnrichmentMode(null);
        } else {
            aiResponse = "I'm sorry, I didn't understand that.  answer with 'email', 'phone', or 'both'.";
        }
    }

    // Create AI message object
    const aiMessage = {
        id: uuidv4(),
        type: 'ai',
        message: aiResponse,
        time: new Date().toISOString()
    };

    try {
      if (aiResponse){
         // First update the local state with both messages
        setMessages(prev => [...prev, aiMessage]);
        await sendMessageToChat(activeSessionId, input, aiResponse,user.userId, sessionType);
        // await sendMessageToChat(activeSessionId, '', aiResponse, user.userId, sessionType);
      }
       
       
    } catch (error) {
        console.error('Error handling enrichment response:', error);
        // Handle error appropriately
    }
};

const generateUniqueId = () => {
  return `id-${Date.now()}-${Math.floor(Math.random() * 10000)}`;
};

  const handleSaveContacts = useCallback((contacts) => {
    // Implement the logic to save contacts to OnePageR
    console.log('Saving contacts to OnePgr:', contacts);
    // You can add an API call here to save the contacts
  }, []);

  const performEnrichment = async (enrichmentType, input) => {
    setIsTyping(true);
    try {
      if (!peopleData) {
        throw new Error("No people data available for enrichment");
      }
  
      const response = await enrichPeopleData(peopleData, enrichmentType);
      console.log("enrich people response --->", response);
  
      if (response.success && response.data.enriched_people) {
        const updatedPeopleData = peopleData.map(person => {
          const enrichedPerson = response.data.enriched_people.find(ep =>
            (ep.first_name + ' ' + ep.last_name).toLowerCase() === 
            (person.firstName + ' ' + person.lastName).toLowerCase()
          );
  
          if (enrichedPerson) {
            // Merge all the enriched data with the original person data
            return {
              ...person, // Keep original data
              enriched_company: enrichedPerson.enriched_company,
              enriched_details: {
                education: enrichedPerson.enriched_details.education,
                experience: enrichedPerson.enriched_details.experience,
                industry: enrichedPerson.enriched_details.industry,
                skills: enrichedPerson.enriched_details.skills
              },
              enriched_email: enrichedPerson.enriched_email,
              enriched_linkedin_url: enrichedPerson.enriched_linkedin_url,
              enriched_location: enrichedPerson.enriched_location,
              enriched_name: enrichedPerson.enriched_name,
              enriched_phone: enrichedPerson.enriched_phone,
              enriched_title: enrichedPerson.enriched_title,
              organization: enrichedPerson.organization
            };
          }
          return person;
        });
  
        updatedPeopleData.forEach(person => {
          person['id2'] = generateUniqueId();
        })
        console.log("updated people data--->", updatedPeopleData);
        setEnrichedPeopleData(updatedPeopleData);
        setIsEnriched(true);
  
        const dataMessage = {
          id: uuidv4(),
          type: 'ai',
          message: { enrichedPeopleData: updatedPeopleData },
          time: new Date().toISOString()
        };
  
        const successMessage = {
          id: uuidv4(),
          type: 'ai',
          message: `Enrichment completed successfully`,
          time: new Date().toISOString()
        };
  
        await sendMessageToChat(
          activeSessionId, 
          input, 
          JSON.stringify(dataMessage.message), 
          user.userId, 
          sessionType
        );
  
        setMessages(prev => [...prev, dataMessage, successMessage]);
      } else {
        throw new Error(response.error || 'Unknown error occurred during enrichment');
      }
    } catch (error) {
      console.error('Error enriching data:', error);
      const errorMessage = {
        id: uuidv4(),
        type: 'ai',
        message: `Error enriching data: ${error.message}`,
        time: new Date().toISOString()
      };
      setMessages(prev => [...prev, errorMessage]);
    } finally {
      setIsTyping(false);
      setEnrichmentMode(null);
    }
  };

  const handleEditMessage = (messageId) => {
    setEditingMessageId(messageId);
  };

  const handleCancelEdit = () => {
    setEditingMessageId(null);
  };

  const handleSaveEdit = async (messageId, editedContent) => {
    setMessages(prev => prev.map(msg =>
      msg.id === messageId ? { ...msg, message: editedContent } : msg
    ));
    setEditingMessageId(null);

    // Reprocess the edited message
    await handleSendMessage(editedContent);
  };

  const getAvatarContent = () => {
    if (!user) return '';
    if (user.avatar) return <img src={user.avatar} alt="User avatar" className="user-avatar-img" />;
    return user.name.charAt(0).toUpperCase();
  };

  const handleToggleWelcomeMessage = () => {
    setShowWelcomeMessage(!showWelcomeMessage);
  };

  const handleSequenceCreation = async (input, userMessage) => {
    let identificationMessage;
    const lowerInput = input.toLowerCase().trim();
    // const userMessage = { id: uuidv4(), type: 'user', content: input, time: new Date().toISOString() };
    // setMessages(prev => [...prev, userMessage]);
    // await sendMessageToChat(activeSessionId, input, userMessage, user.userId, sessionType);
    let aiMessage;
    if (sequenceMode === 'yesno') {
      if (lowerInput === 'yes') {
        aiMessage = {
          id: uuidv4(),
          type: 'ai',
          message: "How would you like to add this step? Options are: new sequence or existing sequence",
          time: new Date().toISOString()
        };
        setSequenceMode('type');

      } else if (lowerInput === 'no') {
        aiMessage = {
          id: uuidv4(),
          type: 'ai',
          message: 'Alright, no sequence creation will be performed.',
          time: new Date().toISOString()
        };
        setSequenceMode(null);
      } else {
        aiMessage = {
          id: uuidv4(),
          type: 'ai',
          message: "I'm sorry, I didn't understand that.  answer with 'yes' or 'no'.",
          time: new Date().toISOString()
        };
      }
    } else if (sequenceMode === 'type') {
      if (['new','existing','new sequence', 'existing sequence'].includes(lowerInput)) {
        // let newMessages = [];
        //     newMessages.push({
        //       id: uuidv4(),
        //       type: 'ai',
        //       content: `Started adding this step for ${lowerInput}...`,
        //     });
        //   setMessages(prev => [...prev, ...newMessages]);
        if (lowerInput === 'new sequence' || lowerInput === 'new') {
          const seqData = await createAutomatedSequence();
          console.log('seqData----->', JSON.parse(seqData.response))
          const newListData = JSON.parse(seqData.response);
          setSelectedSequenceList(newListData)
          let stepNum = 1;
          if(newListData.success == '0') {
            console.log('user--->',user)
            const emailStepDatapayload = [];
            const allStepsResp = []
            for (let index = 0; index < emailStep.length; index++) {
              const step = emailStep[index];
              let emailStepData = getEmailStepFormData(newListData.list_id,stepNum,step)
              const payLoadData = {}
              emailStepData.forEach((value, key) => {
                payLoadData[key] = value;
              });
              emailStepDatapayload.push(payLoadData)
              const stepResp =  await AddStepToExistingSequence(emailStepData);
              allStepsResp.push(stepResp)
              stepNum = stepNum+1
            }
            console.log('stepResp---->',allStepsResp)
            const listViewDtls = {
              message: 'Email step added successfully to new sequence',
              list_id:newListData.list_id
            }
            identificationMessage = 'Email step added successfully to new sequence'
            aiMessage = {
              id: uuidv4(),
              type: 'ai',
              message: { newlyCreatedSequence: listViewDtls },
              time: new Date().toISOString()
            };
          }
        
      }
      else if(lowerInput === 'existing sequence' || lowerInput === 'existing') {
        const sequenceList = await getAutomatedSequenceList();
        console.log('sequenceList-->',sequenceList);
        if(sequenceList.success == '0') {
          setAutomatedSequenceList(sequenceList)
          const sequenceListMessage = {
            id: uuidv4(),
            type: 'ai',
            message: { sequenceList: sequenceList },
            time: new Date().toISOString()
          };
          // await sendMessageToChat(activeSessionId, input, JSON.stringify(sequenceListMessage.message), user.userId, sessionType);
  
          setMessages(prev => [...prev,sequenceListMessage]);
        }
     }

        setSequenceMode(null);
      } else {
        aiMessage = {
          id: uuidv4(),
          type: 'ai',
          message: "I'm sorry, I didn't understand that.  answer with 'new sequence' or 'existing sequence'.",
          time: new Date().toISOString()
        };
      }
    }

    if (aiMessage) {
      await sendMessageToChat(activeSessionId, input, aiMessage.message, user.userId, sessionType);
      setMessages(prev => [...prev, aiMessage]);
      if(identificationMessage === 'Email step added successfully to new sequence') {
        console.log('selectedSequenceList----->',selectedSequenceList)
       const dropSeqMessage = {
          id: uuidv4(),
          type: 'ai',
          message: 'Would you like to Drop/Execute this sequence?  (Yes/No)'
        };
        setDropSequenceMode('yesno');
        // await sendMessageToChat(activeSessionId, '', dropSeqMessage.message, user.userId, sessionType);
        setMessages(prev => [...prev, dropSeqMessage]);
      }
    }
  }

  const handleEngageSequenceForLatestContactsList = async (input, userMessage) => {
    const lowerInput = input.toLowerCase().trim();
    let aiMessage;
    let conditions1 = ['use existing sequence','existing sequence']
    let conditions2 = ['create an email','create a email','create email','email']
    if (engageSequenceMode === 'yesno') {
      if (conditions1.includes(lowerInput)) {
        console.log('Call api to get list of sequences')
        const sequenceList = await getAutomatedSequenceList();
        console.log('sequenceList-->',sequenceList);
        if(sequenceList.success == '0') {
          setAutomatedSequenceList(sequenceList)
          const sequenceListMessage = {
            id: uuidv4(),
            type: 'ai',
            message: { sequenceList: sequenceList,isFromSequenceExe:'engageSequenceForLatestContactList' },
            time: new Date().toISOString()
          };
          setMessages(prev => [...prev,sequenceListMessage]);
          setEngageSequenceMode(null);
        }
      } else if (conditions2.includes(lowerInput)) {
        aiMessage = {
          id: uuidv4(),
          type: 'ai',
          message: "How would you like to create an email? 'Standalone Email' or 'Create a Sequence' ",
          time: new Date().toISOString()
        };
        setMessages(prev => [...prev,aiMessage]);
        setEngageSequenceMode('createEmial/createSequence');
      } else {
        aiMessage = {
          id: uuidv4(),
          type: 'ai',
          message: "I'm sorry, I didn't understand that. answer with 'Create an Email' or 'Existing Sequence'",
          time: new Date().toISOString()
        };
        setMessages(prev => [...prev,aiMessage]);
        // setEngageSequenceMode(null);
      }
    } else if (engageSequenceMode === 'createEmial/createSequence') {
        const consitionArr1 = ['standalone email','standalone','email']
        const consitionArr2 = ['create a sequence','create sequence','create','sequence']
        if(consitionArr1.includes(lowerInput)) {
          aiMessage = {
            id: uuidv4(),
            type: 'ai',
            message: `Enter a prompt to create a standalone email.
            Ex: Create a standalone email regarding VPs of Marketing focusing on AI adoption`,
            time: new Date().toISOString()
          };
          setMessages(prev => [...prev,aiMessage]);
          setEngageSequenceMode(null);
        } else if(consitionArr2.includes(lowerInput)) {
          aiMessage = {
            id: uuidv4(),
            type: 'ai',
            message: `Enter a prompt to create a seqeunce.
            Ex: Create a sequence for VPs of Marketing focusing on AI adoption using email`,
            time: new Date().toISOString()
          };
          setMessages(prev => [...prev,aiMessage]);
          setEngageSequenceMode(null);
        } else {
          aiMessage = {
            id: uuidv4(),
            type: 'ai',
            message: "I'm sorry, I didn't understand that. answer with 'Standalone Email' or 'Create Sequence'",
            time: new Date().toISOString()
          };
          setMessages(prev => [...prev,aiMessage]);
        }
    }
  }

  const handleDropSequence = async (input, userMessage) => {
    const lowerInput = input.toLowerCase().trim();
    let aiMessage;
    if (dropSequenceMode === 'yesno') {
      if (lowerInput === 'yes') {
        console.log('call contact list api and show list of contact lists')
        const resp = await getOnepgrContactLists();
        if(resp&&resp.success == '0') {
           console.log('conatct list---->',resp.response.lists_listing);
          const selectListMsg = {
            id: uuidv4(),
            type: 'ai',
            message: { selectContactList: resp.response.lists_listing },
            time: new Date().toISOString()
          };
          setDropSequenceMode(null);
          setMessages(prev => [...prev, selectListMsg]);
        }
      } else if (lowerInput === 'no') {
        aiMessage = {
          id: uuidv4(),
          type: 'ai',
          message: 'Alright, no sequence execution will be performed.',
          time: new Date().toISOString()
        };
        setMessages(prev => [...prev, aiMessage ]);
        setDropSequenceMode(null);
      } else {
        aiMessage = {
          id: uuidv4(),
          type: 'ai',
          message: "I'm sorry, I didn't understand that. answer with 'yes' or 'no'.",
          time: new Date().toISOString()
        };
      }
  }
  }

  const handleSelectedContactList = (selectedContactList)=>{
     console.log('selectedContactList---->',selectedContactList)
     console.log('Show user to selected drop to schedule contacts')
     setSelectedContactList(selectedContactList)
     const selectListMsg = {
      id: uuidv4(),
      type: 'ai',
      message: { selectDropTime: 'Droptime' },
      time: new Date().toISOString()
    };
    setDropSequenceMode(null);
    setMessages(prev => [...prev, selectListMsg]);
  }

  const handleSaveToOnepgrContactlist = (contactList_id)=> {
    console.log('contactList_id---->',contactList_id);
    currentContactList_idRef.current = contactList_id;
    const sequenceEngageMessage = {
      id: uuidv4(),
      type: 'ai',
      message: "Would you like to 'Create an Email' or 'Use Existing Sequence'?",
      time: new Date().toISOString()
    };
    setEngageSequenceMode('yesno');
    setMessages(prev => [...prev,sequenceEngageMessage]);
    setTimeout(() => {
      setAutoScroll(true)
    }, 1000);
  }

  const handleSelectDropTime = async(selectedDropTime)=>{
    console.log('selectedSequenceList----------->',selectedSequenceList)
   const contactlist_id = selectedContactList?selectedContactList.list_id:currentContactList_idRef.current;;
   const sequenceListId = selectedSequenceList.list_id;
   let allContactActions = [];
  // const contactlist_id = '8338';
  // const sequenceListId = '8376';
   const dropId = window.btoa(Date.now().toString());
   let  contactListData =[]
   let actionBlockListData =[]
   let actionBlockSteps = []
   setIsTyping(true);
     console.log('dropId---->',dropId)
    console.log('selectedDropTime---->',selectedDropTime,contactlist_id)
    console.log('Schedue contacts with selected droptime',sequenceListId)
    try {
     const userDetailsResp = await getProfileAttributes(user.userId);
     console.log('userDetails---->',JSON.parse(userDetailsResp))
     setUserDetails(JSON.parse(userDetailsResp));

     const sequenceListResp = await getAutomatedSequenceListDetails(sequenceListId);
     let sequenceData = JSON.parse(sequenceListResp);
     sequenceData['list_flags'] = JSON.parse(sequenceData['list_flags']);
     console.log('sequenceData---->',sequenceData)
     const contactListResp = await getContactsFromConactList(contactlist_id);
     console.log('contactListResp---->',contactListResp)
     contactListData = contactListResp.contacts_list
     console.log('contactListData---->',contactListData)
     const actionBlockListResp = await getSequenceLevelActionBlocks(sequenceListId);
     actionBlockListData = actionBlockListResp.actionblock_list;
     console.log('actionBlockListData--->',actionBlockListData)
      for (let i = 0; i < actionBlockListData.length; i++) {
        const actionBlock = actionBlockListData[i];
        const actionStepResp = await getActionBlockDetails(actionBlock.actionblock_id);
        actionBlockSteps.push(JSON.parse(actionStepResp));
      }
      console.log('actionBlockSteps---->',actionBlockSteps)
      for (let i = 0; i < actionBlockSteps.length; i++) {
        const actionBlock = actionBlockSteps[i];
        await createContactActionSequenceforBatch(contactListData,dropId,actionBlock,selectedDropTime,sequenceData,JSON.parse(userDetailsResp),allContactActions);
      }
      // await createAndScheduleEmails(sequenceData,dropId,allContactActions,selectedDropTime,contactListData);
      scheduledCotactionsRef.current = {
        sequenceData:sequenceData,
        dropId:dropId,
        allContactActions:allContactActions,
        selectedDropTime:selectedDropTime,
        contactListData:contactListData
      }
      setIsTyping(false);
      toggleSequenceReviewModal(true)
    } catch (error) {
       console.log('error in sequence drop---->',error);
       setIsTyping(false);
       const errorMessage = {
        id: uuidv4(),
        type: 'ai',
        message: "Sequence Execution failed",
        time: new Date().toISOString()
      };
      setMessages(prev => [...prev, errorMessage]);
      toggleSequenceReviewModal(false)
    }
 }

 const createContactActionSequenceforBatch =async(dropContacts,dropId,actionBlock,scheduledDropTime,sequenceData,userDtls,allContactActions) =>{
  console.log('sequenceData------------->',sequenceData)
  let tempTime = scheduledDropTime;
  let runningDropTime;
  let openAiMails = [];
  let currentAppAccount = user.userId === '4991'?senderAppAccounts[1]:senderAppAccounts[0];
  let sequenceUnblockedContacts = dropContacts.filter(
    (contact) => contact.autom_seq_disabled != 1
  );
  for (let i = 0; i < sequenceUnblockedContacts.length; i++) {
    let contactDetails = sequenceUnblockedContacts[i];
    const actionBlockDetails = { ...actionBlock };
    actionBlockDetails["user_id"] =user.userId;
    if (actionBlockDetails.msg_subject) {
      actionBlockDetails.msg_subject = formatMessageTitle(actionBlockDetails,contactDetails);
    }
    if (actionBlockDetails.msg_body && sequenceData.email_engine == 2 ) {
      actionBlockDetails.msg_body = await formatMessageBody(
        actionBlockDetails,
        contactDetails
      );
    }
    if(actionBlockDetails.genai_flag == 1){
      let response = await generateAIContent(
        actionBlockDetails.msg_body,
        actionBlockDetails.genai_msg_flag == 1
      );
      if (response) {
        if (actionBlockDetails.genai_msg_flag == 1) {
          actionBlockDetails.msg_subject = response.msg_subject
            .replace(/Subject:/g, "")
            .replace(/<\/p>/g, "")
            .replace(/<strong> /g, "")
            .replace(/<\/strong> /g, "")
            .replace(/<p>/g, "")
            .replace(/<\/b>:/g, "")
            .replace(/<b>:/g, "")
            .replace(/Subject Line:/g, "");
          actionBlockDetails.msg_body = response.msg_body;
        } else actionBlockDetails.msg_body = response;
        // if(sequenceData.email_engine == 1) {
        //   this.mktgMessage=actionBlockDetails.msg_body
        // }
      } else {
        // this.customToastrService.error(
        //   `An unexpected error occured while generating message for ${contactDetails.firstname}. Skipping and Continuing`
        // );
        // this.respCount++;
        // this.totalItemsDropped++;
        // continue;
        console.log('In genai else')
      }
    }
    // if (
    //   actionBlockDetails.parent_thread_id &&
    //   actionBlockDetails.parent_thread_id != "null" &&
    //   actionBlockDetails.parent_thread_id != "undefined"
    // ) {
    //   actionBlockDetails.msg_body = await getFollowUpBody(
    //     actionBlockDetails,
    //     contactDetails
    //   );
    //   actionBlockDetails.msg_subject = await getFollowUpBody( 
    //     actionBlockDetails,
    //     contactDetails,
    //     true
    //   );
    // }
    let contactOffset = sequenceData.email_engine == 2 ? 1 : 0;
    runningDropTime = tempTime = moment(tempTime)
      .local()
      .add(contactOffset, "minutes")
      .utc()
      .toString();
      if(sequenceData.email_engine == 2 && actionBlockDetails.type === "email"){
        tempTime =runningDropTime;
        // this.getDailyDropCount(this.runningDropTime,actionBlockDetails.type);
        // tempTime = runningDropTime;
      }
      actionBlockDetails["step_drop_id"] = dropId;
      actionBlockDetails["step_drop_type"] =user.userId === ""? "immediate": "scheduled";
      actionBlockDetails.contact_id = contactDetails.contact_id;
      actionBlockDetails.action_type = actionBlockDetails.type;
      actionBlockDetails.drop_time = runningDropTime;
      actionBlockDetails.user_id =userDtls.user_id;
      actionBlockDetails.sender_name = userDtls.user_name;
      actionBlockDetails.sender_email = userDtls.user_email;
      actionBlockDetails.receiver_email = contactDetails.email;
      actionBlockDetails.sender_number = userDtls.purch_phone_no;
      actionBlockDetails.account_id = contactDetails.account_id;
      actionBlockDetails.receiver_number = contactDetails.mobile
        ? contactDetails.mobile
        : contactDetails.phone;
      actionBlockDetails.description = currentAppAccount?currentAppAccount.app_account_id:'';
      actionBlockDetails.firstname = contactDetails.firstname;
      actionBlockDetails.lastname = contactDetails.lastname;
      actionBlockDetails.email = contactDetails.email;
      actionBlockDetails.crm_object_value = contactDetails.crm_object_value;
      actionBlockDetails.tags = contactDetails.tags;
      actionBlockDetails.job_queued = "1";
      actionBlockDetails.sdr_owner = currentAppAccount?currentAppAccount.app_username:'';
      let recipientJsonObj = {}
      actionBlockDetails.recipient_json = JSON.stringify(recipientJsonObj);
      if (actionBlockDetails.msg_body || this.sequenceData.email_engine == 1 ){
        // if (actionBlockDetails.type && actionBlockDetails.type === "sms") {
        //   let mobileNumbersArray = await this.mobileNumberCheckup(
        //     contactDetails
        //   );
        //   for (let index = 0; index < mobileNumbersArray.length; index++) {
        //     const number = mobileNumbersArray[index].number;
        //     actionBlockDetails.receiver_number = number;
        //     this.allContactActions.push(actionBlockDetails);
        //   }
        //   this.respCount++;
        //   this.totalItemsDropped++;
        // } else {
          // this.respCount++;
          // this.totalItemsDropped++;
          allContactActions.push(actionBlockDetails);
        // }
        if(actionBlockDetails.genai_flag == 1){
          openAiMails.push(actionBlockDetails)
        }
      }
  }
  // await createAndScheduleEmails(sequenceData,dropId,allContactActions,scheduledDropTime,dropContacts)
  
}

async function createAndScheduleEmails(sequenceData,drop_id,allContactActions,scheduledDropTime,dropContacts){
  // this.isLoading = true;
  // this.insertAndUpdateDropDates();
  console.log('allContactActions---->',allContactActions)
  let res
  if(sequenceData.email_engine == 2){ 
    res = await createAndScheduleContactAction(allContactActions,sequenceData.email_engine,sequenceData.list_flags.advLinkTracking)
  }
  // else{
  //   await this.scheduleCronJob(drop_id);
  //   res = createAndScheduleMarketingCampaign(allContactActions,sequenceData.email_engine,this.mktgMessage,this.sequenceData.listFlags.advLinkTracking)
  //   .toPromise();
  // }
  if (res.status == 0) {
    // this.customToastrService.success(
    //   `${this.emails.length} Contact${
    //     this.emails.length > 1 ? "s" : ""
    //   } submitted for Automated Sequence`
    // );
    // this.close();
    // this.isLoading = false;
    console.log('res--->',res);
    const newMessage = {
      id: uuidv4(),
      type: 'ai',
      message: {viewSequenceReport:sequenceData},
      time: new Date().toISOString()
    };
    setMessages(prev => [...prev, newMessage]);
  } else {
    // this.close();
    // this.isLoading = false;
    // this.customToastrService.error("An error occured");
    console.log('An error occured--->',res)
    const errorMessage = {
      id: uuidv4(),
      type: 'ai',
      message: "Sequence Execution failed",
      time: new Date().toISOString()
    };
    setMessages(prev => [...prev, errorMessage]);
  }
  // if(sequenceHistory)
  await createSequenceHistory(sequenceData,allContactActions,scheduledDropTime,dropContacts)
}

async function createSequenceHistory(sequenceData,allContactActions,scheduledDropTime,dropContacts) {
  let payLoad = {
    list_id: sequenceData.list_id,
    date: new Date(),
    first_contact_email: allContactActions[0].receiver_email,
    last_contact_email:allContactActions[allContactActions.length - 1].receiver_email,
    notes: "",
    drop_time: scheduledDropTime,
    type: "drop",
    total_contacts_added:dropContacts.length,
    contacts_lists:""
  };
  await createSequenceHistoryApi(payLoad)
}

async function generateAIContent(msg_body, withSubject) {
  msg_body = msg_body + " Requirement: Email without subject line";
  // Generate a response using OpenAI's API.
  let msg_response = await generateOpenAiResponse(msg_body, 0,{userId:user.userId})
  console.log('msg_response--->',msg_response)
  if (msg_response.status == 0) {
    // If the response was successful, proceed to generate a subject line (if required).
    if (withSubject) {
      let sub_prompt =
        "Write a subject line for the following message: \n\n<br>" +
        msg_response.data;
      let subject_response = generateOpenAiResponse(sub_prompt, 1,{userId:user.userId})
      // If the subject line was generated successfully, return the message body and subject line.
      if (subject_response.status == 0) {
        return {
          msg_body: msg_response.data
            .replace(/<p>/g, "")
            .replace(/<\/p>/g, ""),
          msg_subject: subject_response.data
            .replace(/<p>/g, "")
            .replace(/<\/p>/g, "")
            .replace(/<br>/g, "")
            .replace(/<\/br>/g, "")
            .replace(/<br \/>/g, ""),
        };
      } else return null;
    } else {
      // If a subject line is not required, return just the message body.
      return msg_response.data.replace(/<p>/g, "").replace(/<\/p>/, "");
    }
  } else return null;
}


function formatMessageTitle(actionBlockDetails, contactDetails) {
  actionBlockDetails.msg_subject =  actionBlockDetails.msg_subject
    .replace(/{{CompanyName}}/g, contactDetails.company)
    .replace(/{{FirstName}}/g, contactDetails.firstname)
    .replace(/{{LastName}}/g, contactDetails.lastname)
    .replace(/{{LinkedInURL}}/g, contactDetails.linkedin)
    .replace(/{{CompanyURL}}/g, contactDetails.company_url)
    // .replace(/{{Signature}}/g, this.userService.emailSignature)
    // .replace(/{{SenderName}}/g, this.senderName)
    // .replace(/{{SenderSignature}}/g, this.senderSignature)
    // .replace(/{{Title}}/g, contactDetails.title)
    // .replace(/{{VideoSignature}}/g, this.userService.videoSignature)
    .replace(/{{IceBreaker}}/g, "");
  return actionBlockDetails.msg_subject;
}

async function formatMessageBody(actionBlockDetails, contactDetails) {
  if(contactDetails.custom_fields_json){
    let customFields = JSON.parse(contactDetails.custom_fields_json)
      Object.keys(customFields).forEach((key)=>{
        let macro = `{{${key}}}`;
        actionBlockDetails.msg_body = actionBlockDetails.msg_body.replace(new RegExp(macro,'g'),customFields[key])
      })
  }
  actionBlockDetails.msg_body = actionBlockDetails.msg_body
    .replace(/{{CompanyName}}/g, contactDetails.company)
    .replace(/{{FirstName}}/g, contactDetails.firstname)
    .replace(/{{LastName}}/g, contactDetails.lastname)
    .replace(/{{City}}/g, contactDetails.co_city) 
    .replace(/{{Title}}/g, contactDetails.title)
    .replace(/{{LinkedInURL}}/g, contactDetails.linkedin)
    .replace(/{{CompanyURL}}/g, contactDetails.company_url)
    .replace(/{{State}}/g, contactDetails.add_state)   // for company state, co_street@ is used
    // .replace(/{{Signature}}/g, this.userService.emailSignature)
    // .replace(/{{SenderName}}/g, this.senderName)
    // .replace(/{{SenderSignature}}/g, this.senderSignature)
    // .replace(/{{VideoSignature}}/g, this.userService.videoSignature)
    .replace(/{{IceBreaker}}/g, "");
  // if (groupEmailEnabled) {
  //   if(salutation == 0){
  //     const firstnames = this.selectedContactDetails.map(
  //     (contact) => contact.firstname);
  //   let groupString = firstnames.join(", ");
  //   groupString = groupString.replace(/,([^,]*)$/, ' and$1');
  //   actionBlockDetails.msg_body = actionBlockDetails.msg_body.replace(
  //     /{{FirstName}}/g, groupString);
  //   }
  //   else actionBlockDetails.msg_body = actionBlockDetails.msg_body.replace(
  //     /{{FirstName}}/g, 'All');
  // } 
  actionBlockDetails.msg_body = actionBlockDetails.msg_body.replace(/{{FirstName}}/g, contactDetails.firstname)

  // if (actionBlockDetails.msg_doc_url) {
  //   var res = actionBlockDetails.msg_doc_url.split(",");

  //   for (let i = 0; i < res.length; i++) {
  //     const url = res[i];
  //     if (url != "") {
  //       if (url.includes("doc-item/")) {
  //         if (actionBlockDetails.msg_body.includes(url)) {
  //           this.sharableData.type = 2;
  //           this.sharableData.guest_email = contactDetails.email;
  //           this.sharableData.doc_id = url.slice(url.lastIndexOf("/") + 1);
  //           let resp = await this.sendFileModalService
  //             .getSharableLinkForSequence(this.sharableData)
  //             .toPromise();
  //           // .subscribe((resp: any) => {
  //           let res = JSON.parse(resp.response);
  //           actionBlockDetails.msg_body =
  //             actionBlockDetails.msg_body.replaceAll(
  //               url,
  //               url + `?visitcode=${res.token}`
  //             );
  //           if (actionBlockDetails.contact_action_id) {
  //             actionBlockDetails.msg_body =
  //               actionBlockDetails.msg_body.replaceAll(
  //                 url,
  //                 url + `&contact_action_id=[%DOC_CONTACT_ACTION%]`
  //               );
  //           }
  //           // });
  //         }
  //       } else if (url.includes("recordings/view/")) {
  //         if (actionBlockDetails.msg_body.includes(url)) {
  //           this.sharableData.type = 3;
  //           this.sharableData.guest_email = contactDetails.email;
  //           this.sharableData.video_id = url.slice(url.lastIndexOf("/") + 1);
  //           let resp: any = await this.sendFileModalService
  //             .getSharableLinkForSequence(this.sharableData)
  //             .toPromise();
  //           let res = JSON.parse(resp.response);
  //           actionBlockDetails.msg_body =
  //             actionBlockDetails.msg_body.replaceAll(
  //               url,
  //               url + `?visitcode=${res.token}`
  //             );
  //           if (actionBlockDetails.contact_action_id) {
  //             actionBlockDetails.msg_body =
  //               actionBlockDetails.msg_body.replaceAll(
  //                 url,
  //                 url + `&contact_action_id=[%VIDEO_CONTACT_ACTION%]`
  //               );
  //           }
  //         }
  //       } else if (url.includes("pages/")) {
  //         if (actionBlockDetails.msg_body.includes(url)) {
  //           this.sharableData.guest_email = contactDetails.email;
  //           if (url.includes("helloPage")) {
  //             this.sharableData.type = 3;
  //             const urlObject = new URL(url);
  //             const id = urlObject.searchParams.get("id");
  //             this.sharableData.item_id = id;
  //           } else {
  //             this.sharableData.page_id = url.slice(
  //               url.lastIndexOf("/") + 1,
  //               url.indexOf("?")
  //             );
  //             this.sharableData.type = 1;
  //           }
  //           let resp: any = await this.sendFileModalService
  //             .getSharableLinkForSequence(this.sharableData)
  //             .toPromise();
  //           let res = JSON.parse(resp.response);
  //           if (url.includes("helloPage")) {
  //             actionBlockDetails.msg_body =
  //               actionBlockDetails.msg_body.replaceAll(
  //                 "{{VISIT_CODE}}",
  //                 res.token
  //               );
  //           } else {
  //             actionBlockDetails.msg_body =
  //               actionBlockDetails.msg_body.replaceAll(
  //                 url,
  //                 url +
  //                   `&visitcode=${res.token}&name=${contactDetails.firstname}&email=${this.sharableData.guest_email}`
  //               );
  //           }
  //         }
  //       } else if (url.includes("board")) {
  //         if (actionBlockDetails.msg_body.includes(url)) {
  //           let data = {};
  //           this.sharableData.type = 4;
  //           this.sharableData.guest_email = contactDetails.email;
  //           this.sharableData.board_id = url.slice(
  //             url.lastIndexOf("/") + 1,
  //             url.indexOf("?")
  //           );
  //           let resp: any = await this.sendFileModalService
  //             .getSharableLinkForSequence(data)
  //             .toPromise();
  //           // .subscribe((resp: any) => {
  //           let res = JSON.parse(resp.response);
  //           actionBlockDetails.msg_body =
  //             actionBlockDetails.msg_body.replaceAll(
  //               url,
  //               url + `&visitcode=${res.token}`
  //             );
  //           // });
  //         }
  //       }
  //     }
  //   }
  // }
  return actionBlockDetails.msg_body;
}

  const createAutomatedSequence = async () => {
    console.log('Create a sequence', senderAppAccounts)
    const currentDate = moment().format('DD MMM hh:mm A');
    const formData = new FormData();
    formData.append('name', `AI4Rep-Sequence - ${currentDate}`);
    formData.append('description', '1');
    formData.append('list_type', 'action_block');
    formData.append('email_engine', '2');
    formData.append('list_flags', JSON.stringify({
      dropsPerDay: 25,
      weekDayControl: {
        Monday: true,
        Tuesday: true,
        Wednesday: true,
        Thursday: true,
        Friday: true,
        Saturday: false,
        Sunday: false
      },
      advLinkTracking: false,
      machine_calling_enabled: '0'
    }));
    const appAccountIds = senderAppAccounts.map(item => item.app_account_id).join(',');
    formData.append('sender_app_account_id', appAccountIds);
    const seqResp = await createNewSequence(formData);
    return seqResp
  }

  const AddStepToExistingSequence = async (formData) => {
    console.log('Add step to existing sequence')
    const resp = await addStepToParticularSequence(formData)
    return resp;
  }

const getEmailStepFormData = (list_id,serial_number,stepData) =>{
      const formData = new FormData();
      formData.append('action_type', 'email');
      formData.append('user_id', `${user.userId}`);
      formData.append('list_id', `${list_id}`);
      formData.append('parent_thread_id', null);
      formData.append('actionblock_id', '');
      formData.append('msg_subject', `${stepData.subject}`);
      formData.append('msg_body', `${stepData.msg_body}`);
      formData.append('msg_doc_url', ''); 
      formData.append('time_offset', `${stepData.time_offset}`);
      formData.append('serial_number', serial_number);
      formData.append('parent_thread_variant_id', ''); 
      formData.append('msg_format_type', ''); 
      formData.append('cc_email_list', ''); 
      formData.append('genai_flag', stepData.isgenEmail?'1':'0');
      formData.append('genai_msg_flag', stepData.isgenEmail?'1':'0');
      formData.append('parent_id_variant', ''); 
      formData.append('variant_id', ''); 
      formData.append('abtest_flag', '1');
      formData.append('template_type', ''); 
  return formData;
  }

  const handleSelectedSequence = async(selectedSequence,isFromSequenceExe) => {
    console.log('handleSelectedSequence',selectedSequence)
    setSelectedSequenceList(selectedSequence);
    if(!isFromSequenceExe) {
      let stepNum = selectedSequence.action_blocks_count+1
      const emailStepDatapayload = [];
      const allStepsResp = []
      for (let index = 0; index < emailStep.length; index++) {
        const step = emailStep[index];
        let emailStepData = getEmailStepFormData(selectedSequence.list_id,stepNum,step)
        const payLoadData = {}
        emailStepData.forEach((value, key) => {
          payLoadData[key] = value;
        });
        emailStepDatapayload.push(payLoadData)
        const stepResp =  await AddStepToExistingSequence(emailStepData);
        allStepsResp.push(stepResp)
        stepNum = stepNum+1
      }
      console.log('allStepsResp---->',allStepsResp)
      const listViewDtls = {
        message: `Email step successfully added to ${selectedSequence.list_name}`,
        list_id:selectedSequence.list_id
      }
     const aiMessage = {
        id: uuidv4(),
        type: 'ai',
        message: { newlyCreatedSequence: listViewDtls },
        time: new Date().toISOString()
      };
      if (aiMessage) {
        await sendMessageToChat(activeSessionId, 'existing sequence', aiMessage.message, user.userId, sessionType);
        setMessages(prev => [...prev, aiMessage]);
        const dropSeqMessage = {
          id: uuidv4(),
          type: 'ai',
          message: 'Would you like to Drop/Execute this sequence?  (Yes/No)'
        };
        setDropSequenceMode('yesno');
        setMessages(prev => [...prev, dropSeqMessage]);
      }
    }  else if(isFromSequenceExe === "engageSequenceForLatestContactList") {
      console.log('Engage sequence for latest contact list-------->',selectedContactList)
      const selectListMsg = {
        id: uuidv4(),
        type: 'ai',
        message: { selectDropTime: 'Droptime' },
        time: new Date().toISOString()
      };
      setMessages(prev => [...prev, selectListMsg]);
    } else if(isFromSequenceExe === "handleSequenceExecution") {
      console.log("Execute the sequence---->",selectedDropTime)
      const resp = await getOnepgrContactLists();
      if(resp&&resp.success == '0') {
         console.log('conatct list---->',resp.response.lists_listing);
        const selectListMsg = {
          id: uuidv4(),
          type: 'ai',
          message: { selectContactList: resp.response.lists_listing },
          time: new Date().toISOString()
        };
        setDropSequenceMode(null);
        setMessages(prev => [...prev, selectListMsg]);
      }
   }
  }

  async function handleSequenceExecution(intent, promptInput) {
    console.log("handleSequenceExecution---->", 'handleSequenceExecution');
    // Get Sequence List First 
    const sequenceList = await getAutomatedSequenceList();
    console.log('sequenceList-->',sequenceList);
    if(sequenceList.success == '0') {
      setAutomatedSequenceList(sequenceList)
      const sequenceListMessage = {
        id: uuidv4(),
        type: 'ai',
        message: { sequenceList: sequenceList,isFromSequenceExe:'handleSequenceExecution' },
        time: new Date().toISOString()
      };
      setMessages(prev => [...prev,sequenceListMessage]);
    }
  }

  function normalizeChannelField(channel) {
    if (Array.isArray(channel)) {
      return channel;
    } else if (typeof channel === 'string') {
      return channel.split(',').map(item => item.trim());
    }
    return [];
  }

  async function handleCreateSequenceAgent(intent, promptInput) {
    console.log("intent---->", intent);
    console.log("promptInput---->", promptInput);
    const channels = normalizeChannelField(intent.editedInfo.channel);
    let channelPrompts = channels.map((channel) => {
      if (channel === "email") {
        return {
          prompt: `write an email that ${intent.extractedInfo.title} will find relevant to ${intent.extractedInfo.jobSignal}`,
          type: "email",
        };
      } else if (channel === "LinkedIn") {
        return {
          prompt: `prepare a LinkedIn outreach for ${intent.extractedInfo.title} related to ${intent.extractedInfo.jobSignal}`,
          type: "linkedin",
        };
      } else if (channel === "call script") {
        return {
          prompt: `develop a call script to discuss ${intent.extractedInfo.jobSignal} with ${intent.extractedInfo.title}`,
          type: "call_script",
        };
      } else if (channel === "video message") {
        return {
          prompt: `create a video message introducing ${intent.extractedInfo.jobSignal} to ${intent.extractedInfo.title}`,
          type: "video_message",
        };
      } else if (channel === "SMS" || channel === "sms") {
        return {
          prompt: `compose an SMS highlighting ${intent.extractedInfo.jobSignal} for ${intent.extractedInfo.title}`,
          type: "sms",
        };
      } else if (channel === "genAI email") {
        return {
          prompt: `generate an AI-powered email prompt tailored to ${intent.extractedInfo.jobSignal} for ${intent.extractedInfo.title}`,
          type: "genai_email",
        };
      } else {
        return {
          prompt: `use ${channel} to engage ${intent.extractedInfo.title} with ${intent.extractedInfo.jobSignal}`,
          type: channel,
        };
      }
    });
  
    console.log("channelPrompts--->", channelPrompts);
    for (let i = 0; i < channelPrompts.length; i++) {
      let channelType = channelPrompts[i].type;
      let prompt = channelPrompts[i].prompt;
      if (channelType === 'email' || channelType === 'genai_email') {
        let parsedEmail ;
        if(channelType === 'email'){
          parsedEmail = await processEmailRequest('','','',activeSessionId,prompt);
        }
        if(channelType === 'genai_email'){
          parsedEmail = await createGenAIPrompt('','',activeSessionId,prompt);
        }
        if (parsedEmail) {
          console.log("parsedEmail---->", parsedEmail.email_content);
          let subject = parsedEmail.email_content?parsedEmail.email_content.subject:"Subject not found";
          let body = parsedEmail.email_content?parsedEmail.email_content.body:parsedEmail;
           const emailStepData = {
            subject: subject,
            msg_body: body,
            time_offset: '0',
            isgenEmail: channelType === 'email'?false:true
           }
           setEmailStep(prev => [...prev,emailStepData])
           const emailMessage = {
            id: uuidv4(),
            type: 'ai',
            message: { emailData:{subject:subject,body:body}}
          };
          setMessages(prev => [...prev, emailMessage]);
        }
      } else {
        // const babuResp = await getBabuLangchainResponse(prompt, activeSessionId);
        // console.log("babuResp---->", babuResp);
      //   const babuResp = await getBabuLangchainResponse(prompt, activeSessionId);
      //   const emailMessage = {
      //     id: uuidv4(),
      //     type: 'ai',
      //     message: babuResp.message.response
      //   };
      //   setMessages(prev => [...prev, emailMessage]);
      console.warn("......Need to Implement further steps......")
      }
    }
    setSequenceMode('type');
   let aiMessage = {
      id: uuidv4(),
      type: 'ai',
      message: "How would you like to add this steps to sequence? Options are: new sequence or existing sequence",
      time: new Date().toISOString()
    };
    setMessages(prev => [...prev, aiMessage]);
  }

  const toggleSequenceReviewModal = (val)=>{
    setToggleSequenceReview(val);
  }

  const executeSequence = async () => {
     try {
       toggleSequenceReviewModal(false);
       setIsTyping(true);
       const {
         sequenceData,
         dropId,
         allContactActions,
         selectedDropTime,
         contactListData,
       } = scheduledCotactionsRef.current;
       await createAndScheduleEmails(
         sequenceData,
         dropId,
         allContactActions,
         selectedDropTime,
         contactListData
       );
     } catch (error) {
       setIsTyping(false);
       const errorMessage = {
         id: uuidv4(),
         type: "ai",
         message: "Sequence Execution failed",
         time: new Date().toISOString(),
       };
       setMessages((prev) => [...prev, errorMessage]);
     } finally {
       setIsTyping(false);
     }
  }

  return (
    <div className="chat-container">
      <NavBar toggleSidebar={toggleSidebar} onLogout={handleLogout} />
      <div
        ref={sidebarTriggerRef}
        className="sidebar-trigger"
        style={{
          position: 'fixed',
          left: 0,
          top: 0,
          width: '10px',
          height: '100%',
          zIndex: 1000
        }}
      />
      <div
        ref={sidebarRef}
        className={`sidebar-wrapper ${isSidebarOpen ? 'open' : ''}`}
        onMouseLeave={handleSidebarMouseLeave}
      > <Sidebar
          onLogout={onLogout}
          onNewChat={handleNewChat}
          userId={user.userId}
          recentChats={recentChats}
          activeSessionId={activeSessionId}
          onChatSelect={(sessionId) => navigate(`/chat/${sessionId}`)}
        />
      </div>
      <div className={`main-content ${isSidebarOpen ? 'shifted' : ''}`}>
        <div className="message-area" ref={messageAreaRef}>
          <MessageList
            messages={messages}
            onEditMessage={handleEditMessage}
            editingMessageId={editingMessageId}
            onCancelEdit={handleCancelEdit}
            onSaveEdit={handleSaveEdit}
            reviewData={reviewData}
            onReviewAction={handleReviewAction}
            isTyping={isTyping}
            showWelcomeMessage={showWelcomeMessage}
            onToggleWelcomeMessage={handleToggleWelcomeMessage}
            onExamplePromptClick={handleExamplePromptClick}
            isEnriched={isEnriched}
            onSaveContacts={handleSaveContacts}
            handleSelectedSequence={handleSelectedSequence}
            handleSelectedContactList={handleSelectedContactList}
            handleSelectDropTime= {handleSelectDropTime}
            handleSaveToOnepgrContactlist={handleSaveToOnepgrContactlist}
            onTriggerEnrichment={handleTriggerEnrichment} 
          />

          <div ref={messagesEndRef} />
        </div>

        <MessageInput onSendMessage={handleUserInput} disabled={isTyping} enrichmentMode={enrichmentMode}
        sequenceMode ={sequenceMode} dropSequenceMode = {dropSequenceMode} engageSequenceMode ={engageSequenceMode}
        />
        {showScrollButton && (
          <button onClick={scrollToBottom} className="scroll-button">
            <ChevronDown size={20} />
          </button>
        )}
      </div>
      {user && (
        <div className={`user-info-avatar ${isSidebarOpen ? 'hidden' : ''}`} >
          <div className="user-fixed-avatar" onClick={toggleSidebar}>
            {getAvatarContent()}
          </div>
        </div>
      )}
        {toggleSequenceReview && (
        <ReviewSequenceModal executeSequence={executeSequence}
        toggleSequenceReviewModal={toggleSequenceReviewModal}
        contactActionsData = {scheduledCotactionsRef.current}
        />
      )}
    </div>
  );
};

export default ChatInterface;