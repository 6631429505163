import React, { useState, useEffect } from "react";
import "../styles/SelectListDropTime.css";
import { DateTimePicker } from "@mui/x-date-pickers";
import dayjs from 'dayjs';
import { Dialog, DialogTitle, DialogActions, DialogContent, styled } from '@mui/material';
import ReviewSequenceModal from "./sharedComponents/reviewSequence/ReviewSequenceModal";

// Custom Dialog Paper component using `styled`
const CustomDialogPaper = styled(Dialog)(({ theme }) => ({
  backgroundColor: 'red',
  borderRadius: '40px',
  padding: '20px',
  '& .MuiDialogTitle-root': {
    fontSize: '25px',
    fontWeight: 'bold',
  },
  '& .MuiDialogActions-root': {
    padding: '10px 20px',
  }
}));

const SelectListDropTime = ({
  isSelectContactList,
  isSelectDropTime,
  contactList,
  handleContactListChange,
  handlDropTimeChane,
  viewSequenceReport,
  isViewSequenceReport,
  newlyCreatedSequence,
}) => {
  const [selectedContact, setSelectedContact] = useState("");
  const [selectedDateTime, setSelectedDateTime] = useState("");
  const defaultTime = dayjs().add(30, 'minute');
  const [value, setValue] = React.useState(defaultTime);
  const [toggleSequenceReview, setToggleSequenceReview] = useState(false);

  useEffect(() => {
    setSelectedDateTime(getCurrentDateTime());
  }, []);

  const handleContactChange = (event) => {
    setSelectedContact(event.target.value);
    const list = contactList.find((list) => list.list_id === event.target.value);
    handleContactListChange(list);
  };

  const handleDateTimeChange = (event) => {
    const selectedDateTimeValue = event.target.value;
    setSelectedDateTime(selectedDateTimeValue);
  };

  const handleNextClick = () => {
    // handlDropTimeChane(selectedDateTime);
    console.log('selectedDateTime---->',selectedDateTime)
    console.log('value---->',value.$d);
    handlDropTimeChane(value.$d);
  };

  const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  const handleViewReports = () =>{

    console.log('viewSequenceReport----->',viewSequenceReport)
    window.open(`https://meet.onepgr.com/sequences/queued-contact/${viewSequenceReport.list_id}?type=sequence&share_mode=0`, '_blank');
  }

  const hadleCreatedSequenceView = () =>{
    window.open(`https://meet.onepgr.com/sequences/${newlyCreatedSequence.list_id}`, '_blank');
  }

  const toggleSequenceReviewModal = (val)=>{
    setToggleSequenceReview(val);
  }
    

  return (
    <div className="select-list-drop-time-container">
      {isSelectContactList && (
        <div>
          <label>
            Select contact list to drop sequence:
            <select
              className="select-list-drop-filter-input"
              value={selectedContact}
              onChange={handleContactChange}
            >
              <option value="" disabled>
                Select contact list
              </option>
              {contactList.map((list, index) => (
                <option key={index} value={list.list_id}>
                  {list.list_name}
                </option>
              ))}
            </select>
          </label>
        </div>
      )}

      {isSelectDropTime && (
        <div>
          {/* <label>
            Select Drop time to drop sequence:
            <input
              className="select-list-drop-filter-input filter-input-date-time"
              type="datetime-local"
              value={selectedDateTime}
              min={getCurrentDateTime()}
              onChange={handleDateTimeChange}
            />
          </label>  */}
          <div className="date-time-picker-container">
            <label>Select Drop Time to Drop Sequence:</label>
            <DateTimePicker
              value={value}
              onChange={(newValue) => setValue(newValue)}
              minDateTime={dayjs()}
            />
          </div>
           <div className="drop-sequence-container">
           {/* <button
            className="next-button drop-sequence-btn"
            onClick={()=>{toggleSequenceReviewModal(true)}}
          >
            Preview Sequence
          </button> */}
          <button
            className="next-button drop-sequence-btn"
            onClick={handleNextClick}
          >
            Schedule Sequence
          </button>
           </div>
        </div>
      )}

      {isViewSequenceReport && (
        <div className="view-sequence-report">
          <label>Sequence executed successfully:</label>
          <button className="next-button" onClick={handleViewReports}>
            View Reports
          </button>
        </div>
      )}
      {newlyCreatedSequence && (
        <div className="view-sequence-report">
          <label>{newlyCreatedSequence.message}</label>
          <button className="next-button" onClick={hadleCreatedSequenceView}>
            View Sequence
          </button>
        </div>
      )}

      {/* {toggleSequenceReview && (
        <ReviewSequenceModal/>
      )} */}
    </div>
  );
};

export default SelectListDropTime;
