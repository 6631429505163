
import React, { useState, useMemo } from 'react';
import '../styles/peopleTable.css';

const PeopleTable = ({ people, isEnriched, onEnrichContacts }) => {
  // console.log('people--->', people);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [filter, setFilter] = useState('');
  const [selectedContacts, setSelectedContacts] = useState([]);


  const handleCheckboxChange = (person) => {
    setSelectedContacts(prev =>
      prev.some(contact => contact.id === person.id)
        ? prev.filter(contact => contact.id !== person.id)
        : [...prev, person]
    );
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedContacts(filteredPeople);
    } else {
      setSelectedContacts([]);
    }
  };

  const handleEnrichClick = () => {
    if (selectedContacts.length > 0) {
      onEnrichContacts(selectedContacts);
    }
  };

  const sortedPeople = useMemo(() => {
    let sortablePeople = [...people];
    if (sortConfig.key !== null) {
      sortablePeople.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortablePeople;
  }, [people, sortConfig]);

  const formatPerson = (person) => {
    // console.log("person data--->",person , isEnriched)
    const formatLocation = () => {
      if (person.location) return person.location;
      const city = person.city || '';
      const country = person.country || '';
      return city && country ? `${city}, ${country}` : city || country || 'N/A';
    };

    return {
      name: person.name || `${person.first_name} ${person.last_name}`,
      title: person.title || 'N/A',
      company: person.company || person.company_name || person.organization_name || (person.organization && person.organization.name) || 'N/A',
      location: formatLocation(),
      email: person.email === 'email_not_unlocked@domain.com' ? isEnriched ? "N/A" : 'Locked' : (person.email || 'N/A'),
      phone: person.phone_numbers && person.phone_numbers.length > 0 ? person.phone_numbers[0].number : 'N/A',
      linkedin_url: person.linkedin_url || 'N/A'
    };
  };



  const filteredPeople = sortedPeople.filter(person => {
    const searchStr = filter.toLowerCase();
    const formattedPerson = formatPerson(person);
    return Object.values(formattedPerson).some(value =>
      value.toString().toLowerCase().includes(searchStr)
    );
  });

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  return (
    <div className="people-table-container">

      <table className="people-table table-container">
        <thead>
          <tr>
            {/* {isEnriched && <th>Select</th>} */}
            <th>
              <input
                type="checkbox"
                onChange={handleSelectAll}
                checked={selectedContacts.length === filteredPeople.length && filteredPeople.length > 0}
                className="cursor-pointer"
              />
            </th>
            <th className='tableName' > <p onClick={() => requestSort('name')}>Name {sortConfig.key === 'name' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}</p></th>
            <th onClick={() => requestSort('title')}>Title {sortConfig.key === 'title' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}</th>
            <th onClick={() => requestSort('company')}>Company {sortConfig.key === 'company' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}</th>
            <th onClick={() => requestSort('location')}>Location {sortConfig.key === 'location' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}</th>
            {/* <th>Email</th>
            <th>Phone</th>*/}
            <th>LinkedIn</th>
          </tr>
        </thead>
        <tbody>
          {filteredPeople.map((person, index) => {
            const formattedPerson = formatPerson(person);
            return (
              <tr key={person.id || index}>
                <td>
                  <input
                    type="checkbox"
                    checked={selectedContacts.some(contact => contact.id === person.id)}
                    onChange={() => handleCheckboxChange(person)}
                  />
                </td>

                <td className='tableName-value'><p>{formattedPerson.name}</p></td>
                <td>{formattedPerson.title}</td>
                <td>{formattedPerson.company}</td>
                <td>{formattedPerson.location}</td>

                <td>
                  {formattedPerson.linkedin_url !== 'N/A' ? (
                    <a
                      href={formattedPerson.linkedin_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Profile
                    </a>
                  ) : (
                    'N/A'
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="table-footer">
        <p>Total people found: {filteredPeople.length}</p>
        <button
          onClick={handleEnrichClick}
          disabled={selectedContacts.length === 0}
          className={`${selectedContacts.length === 0 ? 'opacity-50' : 'enrich-selected-btn'
            }`}
        >
          Enrich Selected Contacts ({selectedContacts.length})
        </button>
      </div>
    </div>
  );
};

export default PeopleTable;