
// import React, { useState, useEffect } from 'react';
// import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
// import ChatInterface from './components/chatinteface';
// import Login from './components/loginAi';
// import { UserProvider } from './components/context/userContext';
// import logoutUser from './components/logoutUser';

// function AppContent() {
//   const [isAuthenticated, setIsAuthenticated] = useState(false);
//   const navigate = useNavigate();

//   useEffect(() => {
//     const storedUser = localStorage.getItem('user');
//     if (storedUser) {
//       setIsAuthenticated(true);
//     }
//   }, []);

//   const handleLogin = () => {
//     setIsAuthenticated(true);
//     navigate('/chat');
//   };

//   const handleLogout = () => {
//     logoutUser(navigate)
//       .then(() => {
//         setIsAuthenticated(false);
//         navigate('/login');
//       })
//       .catch((error) => {
//         console.error('Logout error:', error);
//         // Handle any logout errors here
//       });
//   };

//   return (
//     <div className="App">
//       <Routes>
//         <Route path="/login" element={
//           isAuthenticated ? <Navigate to="/chat" /> : <Login onLogin={handleLogin} />
//         } />
//         <Route path="/chat" element={
//           isAuthenticated ? <ChatInterface onLogout={handleLogout} /> : <Navigate to="/login" />
//         } />
//         <Route path="/chat/:sessionId" element={
//           isAuthenticated ? <ChatInterface onLogout={handleLogout} /> : <Navigate to="/login" />
//         } />
//         <Route path="/" element={<Navigate to="/chat" />} />
//         <Route path="*" element={<Navigate to="/login" />} />
//       </Routes>
//     </div>
//   );
// }

// function App() {
//   return (
//     <UserProvider>
//       <Router>
//         <AppContent />
//       </Router>
//     </UserProvider>
//   );
// }

// export default App;

import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import ChatInterface from './components/chatinteface';
import Login from './components/loginAi';
import { CombinedProvider, useCombinedContext } from './components/context/userContext';
import logoutUser from './components/logoutUser';
import AICampaign from './components/aiCampaign/AICampaign';
import AccountSettings from './components/accountSettings/accountSettings';
import ApplicationTesting from './components/application testing/applicationtesting';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
function AppContent() {
  const { user, setUser } = useCombinedContext();

  const handleLogout = async () => {
    try {
      await logoutUser();
      setUser(null);
    } catch (error) {
      console.error('AppContent: Logout error:', error);
    }
  };

  return (
    <div className="App">
      <Routes>
        <Route path="/login" element={
          user ? <Navigate to="/chat" replace /> : <Login />
        } />
        <Route path="/chat" element={
          user ? <ChatInterface onLogout={handleLogout} /> : <Navigate to="/login" replace />
        } />
        <Route path="/chat/:sessionId" element={
          user ? <ChatInterface onLogout={handleLogout} /> : <Navigate to="/login" replace />
        } />
        <Route path="/ai-campaign" element={  user ? <AICampaign onLogout={handleLogout} /> : <Navigate to="/login" replace />} />
        <Route path="/account-settings" element={user ? <AccountSettings onLogout={handleLogout}  /> : <Navigate to="/login" replace />} />
        <Route path="/application-testing" element={user ? <ApplicationTesting onLogout={handleLogout} /> : <Navigate to="/login" replace />} />
        <Route path="/application-testing/:sessionId" element={
          user ? <ApplicationTesting onLogout={handleLogout} /> : <Navigate to="/login" replace />
        } />
        <Route path="/" element={<Navigate to="/chat" replace />} />
        <Route path="*" element={<Navigate to="/login" replace />} />

      </Routes>
    </div>
  );
}

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
    <CombinedProvider>
      <Router>
        <AppContent />
      </Router>
    </CombinedProvider>
    </LocalizationProvider>
  );
}

export default App;