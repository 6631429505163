import { useRef, useState } from 'react';
import axios from 'axios';

const useOnePgrFunctions = () => {
  const [loading, setLoading] = useState(false);
  const[currentContactListList_id, setCurrentContactListList_id] = useState(null);
  const contactlistIdref = useRef(null);

  const createContactList = (name) => {
    console.log("list name-->", name);
    const ListName = name.name.trim();
    const body = new FormData();
    body.append("name", ListName);
    body.append("description", "1");
    body.append("list_type", "contact");
    return axios.post("https://onepgr.com/lists/create_api?onepgr_apicall=1&xhr_flag=1", body, { withCredentials: true });
  };

  const uploadMultipleContacts = (contacts_list) => {
    const body = new FormData();
    body.append("contacts_list", JSON.stringify(contacts_list));
    body.append("import_source_id", "3");
    body.append("import_source_name", "import-contact");
    return axios.post("https://onepgr.com/contacts/create2_multi_contacts_api?onepgr_apicall=1&xhr_flag=1", body, { withCredentials: true });
  };

  const parseLocation = (location) => {
    if (!location) return { city: '', state: '', country: '' };
    const parts = location.split(', ');
    return {
      city: parts[0] || '',
      state: parts[1] || '',
      country: parts[2] || ''
    };
  };

  const handleSave = async (listData, selectedItems, showNotification) => {
    if (!selectedItems || Object.keys(selectedItems).length === 0) {
      showNotification('No contacts selected', 'warning');
      return false;
    }

    setLoading(true);
    try {
      let listId;
      let listName;

      // Handle existing vs new list
      if (listData.type === 'existing') {
        listId = listData.id;
        listName = listData.name;
      } else {
        // Create new list only if type is 'new'
        const listResponse = await createContactList(listData);
        const parsedListData = JSON.parse(listResponse.data.response);
        listId = parsedListData.list_id;
        listName = listData.name;
      }
      setCurrentContactListList_id(listId);
      contactlistIdref.current = listId;
      const selectedContacts = Object.values(selectedItems).map(contact => {
        const { city, state, country } = parseLocation(contact.location);
        
        return {
          contact_id: "",
          name: contact.name || null,
          email: contact.email || null,
          phone: contact.phone || null,
          mobile: null,
          twitter: "",
          facebook: null,
          linkedin: contact.linkedin_url || null,
          skype: null,
          status: null,
          add_nu: null,
          add_str1: null,
          add_str2: null,
          add_city: city || null,
          add_state: state || null,
          add_zip: null,
          add_country: country || null,
          notes: null,
          company: contact.company || null,
          account_id: "",
          account_name: "",
          account_state: "",
          tags: null,
          page_id: "",
          contact_type: "",
          title: contact.jobTitle || "",
          role_sales_process: "",
          import_source_id: "3",
          import_source_name: "import-contact",
          ref_user_id: "",
          firstname: contact.first_name || null,
          lastname: contact.last_name || null,
          email_2: null,
          email_3: null,
          company_url: contact.domain || null,
          list_id: listId,
          autom_seq_disabled: null,
          unsubscribed: null,
          segment_id: null,
          crm_object_value: null,
          co_employee_size: null,
          co_street1: null,
          co_street2: null,
          co_city: city || null,
          co_zip: null,
          co_country: country || null,
          co_phone: null,
          co_linkedin: null,
          co_twitter: null,
          lead_status: null,
          custom_fields_json: JSON.stringify({
            "Lead Location": contact.location,
            "Industry": "N/A",
            "Upload Date": new Date().toLocaleDateString(),
            "Full Name": contact.name,
            "List Name": listName
          }),
          updated_at: new Date().toLocaleString('en-US', {
            day: '2-digit',
            month: 'short',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
          })
        };
      });

      const contactList = {
        contacts: selectedContacts
      };

      const response = await uploadMultipleContacts(contactList);
      const responseData = JSON.parse(response.data.response);

      if (responseData.success === '0' && responseData.success_msg) {
        showNotification(`${responseData.success_count} contacts saved to OnePgr list "${listName}"`, 'success');
        return true;
      } else if (responseData.success === '1') {
        showNotification(`Contacts saved to OnePgr list "${listName}" successfully`, 'success');
        return true;
      } else {
        throw new Error('Unexpected response from server');
      }
    } catch (error) {
      console.error('Error saving contacts to OnePgr:', error);
      const listName = listData.type === 'existing' ? listData.name : listData.name.name;
      showNotification(`Failed to save contacts to OnePgr list "${listName}"`, 'error');
      return false;
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    handleSave,
    currentContactListList_id,
    contactlistIdref
  };
};

export default useOnePgrFunctions;