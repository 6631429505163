import React, { useState } from 'react';
import './textarea.css';

const TextAreaForTesting = ({ testResponse }) => {
  const [activeTab, setActiveTab] = useState('payload');

  // Function to format JSON with proper indentation
  const formatJSON = (data) => {
    try {
      return JSON.stringify(data, null, 2);
    } catch (error) {
      return "Invalid JSON";
    }
  };

  return (
    <div className="test-container">
      <h4>Prompt Processor</h4>
      <div className="test-card">
        <div className="test-tab-list">
        <button
            className={`test-tab-button ${activeTab === 'intentDetection' ? 'active' : ''}`}
            onClick={() => setActiveTab('intentDetection')}
          >
            Intent Detection
          </button>
          <button
            className={`test-tab-button ${activeTab === 'preprocessing' ? 'active' : ''}`}
            onClick={() => setActiveTab('preprocessing')}
          >
            Pre Processing
          </button>
          <button
            className={`test-tab-button ${activeTab === 'apiCall' ? 'active' : ''}`}
            onClick={() => setActiveTab('apiCall')}
          >
            API Call
          </button>
          <button
            className={`test-tab-button ${activeTab === 'response' ? 'active' : ''}`}
            onClick={() => setActiveTab('response')}
          >
            Response
          </button>
          <button
            className={`test-tab-button ${activeTab === 'recognizedIntent' ? 'active' : ''}`}
            onClick={() => setActiveTab('recognizedIntent')}
          >
            Post Processing
          </button>
          {/* <button
            className={`test-tab-button ${activeTab === 'nextPrompt' ? 'active' : ''}`}
            onClick={() => setActiveTab('nextPrompt')}
          >
            Next Prompt
          </button> */}
        </div>

        <div className="test-content-area">
        {activeTab === 'intentDetection' && (
            <textarea
              className="test-textarea"
              readOnly
              value={formatJSON(testResponse.intent,'intentDetection')}
            />
          )}
          {activeTab === 'preprocessing' && (
            <textarea
              className="test-textarea"
              readOnly
              value={formatJSON(testResponse.payload,'preprocessing')}
            />
          )}
          {activeTab === 'apiCall' && (
            <textarea
              className="test-textarea"
              readOnly
              value={formatJSON(testResponse.apiCall,'apiCall')}
            />
          )}
          {activeTab === 'response' && (
            <textarea
              className="test-textarea"
              readOnly
              value={formatJSON(testResponse.response,'apiCall')}
            />
          )}
          {activeTab === 'recognizedIntent' && (
            <textarea
              className="test-textarea"
              readOnly
              value={formatJSON(testResponse.recognizedIntent,'recognizedIntent')}
            />
          )}
          {/* {activeTab === 'nextPrompt' && (
            <textarea
              className="test-textarea"
              readOnly
              value={formatJSON(testResponse.nextPrompt)}
            />
          )} */}
        </div>
      </div>
    </div>
  );
};

export default TextAreaForTesting;