import axios from "axios";
import { v4 as uuidv4 } from "uuid";
const onepgrAPiUrl = "https://onepgr.com";

export const getSenderAppAccounts = async () => {
    try {
        const formData = new FormData()
        formData.append('query_app_id','114')
      const response = await axios.post(
        `${onepgrAPiUrl}/app_accounts/app_account_index_api?onepgr_apicall=1&xhr_flag=1`,
        formData,
        { withCredentials: true }
      );
      console.log('app account resp----->',response.data)
      return response.data;
    } catch (error) {
      console.log("Error While creating New Sequence", error);
    }
  };

export const getAutomatedSequenceList = async () => {
    try {
      const formData = new FormData()
      formData.append('list_type','action_block')
      const response = await axios.post(
        `${onepgrAPiUrl}/lists/get_list_summary?onepgr_apicall=1&xhr_flag=1`,
        formData,
        { withCredentials: true }
      );
      console.log('sequenceList resp----->',response.data)
      return response.data;
    } catch (error) {
      console.log("Error While creating New Sequence", error);
    }
 };

export const createNewSequence = async (formData) => {
  try {
    const response = await axios.post(
      `${onepgrAPiUrl}/lists/create_api?onepgr_apicall=1&xhr_flag=1`,
      formData,
      { withCredentials: true }
    );
    console.log('seq resp----->',response.data)
    return response.data;
  } catch (error) {
    console.log("Error While creating New Sequence", error);
  }
};

export const addStepToParticularSequence = async (formData) => {
    try {
        const response = await axios.post(
          `${onepgrAPiUrl}/action_blocks/create_api?onepgr_apicall=1&xhr_flag=1`,
          formData,
          { withCredentials: true }
        );
        console.log('Step resp----->',response.data)
        return response.data;
      } catch (error) {
        console.log("Error While creating New Sequence", error);
      }
};

export const getOnepgrContactLists = async () => {
  try {
    const formData = new FormData()
    formData.append('onepgr_apicall','1')
    formData.append('xhr_flag','1')
    formData.append('list_type','contact')
      const response = await axios.post(
        `${onepgrAPiUrl}/lists/index_api`,
        formData,
        { withCredentials: true }
      );
      console.log('contactlistresp----->',response.data)
      return response.data;
    } catch (error) {
      console.log("Error While creating New Sequence", error);
    }
};

export const getAutomatedSequenceListDetails = async (id) => {
  try {
    const formData = new FormData()
    formData.append('id',id)
      const response = await axios.post(
        `${onepgrAPiUrl}/lists/show_api?onepgr_apicall=1&xhr_flag=1`,
        formData,
        { withCredentials: true }
      );
      console.log('contactlistresp----->',response.data)
      return response.data.response;
    } catch (error) {
      console.log("Error While creating New Sequence", error);
    }
};

export const getSequenceLevelActionBlocks = async (list_id) => {
  try {
    const formData = new FormData()
    formData.append('list_id',list_id)
      const response = await axios.post(
        `${onepgrAPiUrl}/action_blocks/index_api?onepgr_apicall=1&xhr_flag=1`,
        formData,
        { withCredentials: true }
      );
      console.log('actionBlockList----->',response.data.response)
      return response.data.response;
    } catch (error) {
      console.log("Error While getSequenceLevelActionBlocks", error);
    }
};

export const getActionBlockDetails = async (actionBlockId) => {
  try {
    const formData = new FormData()
    formData.append('id',actionBlockId)
      const response = await axios.post(
        `${onepgrAPiUrl}/action_blocks/show_api?onepgr_apicall=1&xhr_flag=1`,
        formData,
        { withCredentials: true }
      );
      console.log('actionBlockList----->',response.data.response)
      return response.data.response;
    } catch (error) {
      console.log("Error While getActionBlockDetails", error);
    }
};

export const getContactsFromConactList = async (list_id) => {
  try {
    const formData = new FormData()
    formData.append('list_id',list_id)
    formData.append('nitems','100')
    formData.append('page_num','1')
    formData.append('reachview','1')
      const response = await axios.post(
        `${onepgrAPiUrl}/contacts/index2_api?onepgr_apicall=1&xhr_flag=1`,
        formData,
        { withCredentials: true }
      );
      console.log('contactsList----->',response.data)
      return response.data.response;
    } catch (error) {
      console.log("Error While creating New Sequence", error);
    }
};

export const getProfileAttributes = async (user_id) => {
  console.log(user_id)
  try {
    const formData = new FormData()
    formData.append('id',user_id);
    formData.append('public_view','1');
      const response = await axios.post(
        `${onepgrAPiUrl}/users/get_profile_attributes?onepgr_apicall=1&xhr_flag=1`,
        formData,
        { withCredentials: true }
      );
      console.log('userDetails----->',response.data)
      return response.data.response;
    } catch (error) {
      console.log("Error While creating New Sequence", error);
    }
};

