import React, { useState } from 'react';
import '../styles/companyLIstTable.css';

const CompanyListTable = ({ companies = [] }) => {
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [filter, setFilter] = useState('');
  console.log('companies-------____>',companies)
  const sortedCompanies = React.useMemo(() => {
    let sortableCompanies = [...companies];
    if (sortConfig.key !== null) {
      sortableCompanies.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableCompanies;
  }, [companies, sortConfig]);

  if (!companies || companies.length === 0) {
    return <p>No company data available.</p>;
  }

  const formatCompany = (company) => ({
    companyDomain: company.companyDomain || 'N/A',
    companyLocation: company.companyLocation || 'N/A',
    companyName: company.companyName || 'N/A',
  });

  const filteredCompanies = sortedCompanies.filter(company => {
    const searchStr = filter.toLowerCase();
    const formattedCompany = formatCompany(company);
    return Object.values(formattedCompany).some(value => 
      value.toString().toLowerCase().includes(searchStr)
    );
  });

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  return (
    <div className="company-table-container">
      {/* <input
        type="text"
        placeholder="Filter companies..."
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
        className="company-filter-input"
      /> */}
      <table className="company-table table-container">
        <thead>
          <tr>
            <th onClick={() => requestSort('companyName')}>
              Company Name {sortConfig.key === 'companyName' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
            </th>
            <th onClick={() => requestSort('companyDomain')}>
              Domain {sortConfig.key === 'companyDomain' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
            </th>
            <th onClick={() => requestSort('companyLocation')}>
              Location {sortConfig.key === 'companyLocation' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
            </th>
          </tr>
        </thead>
        <tbody>
          {filteredCompanies.map((company, index) => {
            const formattedCompany = formatCompany(company);
            return (
              <tr key={company.companyDomain || index}>
                <td>{formattedCompany.companyName}</td>
                <td>{formattedCompany.companyDomain}</td>
                <td>{formattedCompany.companyLocation}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <p>Total companies found: {filteredCompanies.length}</p>
    </div>
  );
};

export default CompanyListTable;