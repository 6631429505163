import axios from 'axios';

const logoutUser = async () => {
    try {
        const userData = JSON.parse(localStorage.getItem('user'));
        console.log("User data from localStorage:", userData);

        if (!userData || !userData.sessionToken) {
            console.error('No user data or session token found');
            throw new Error('No session token available');
        }

        const formData = new FormData();
        formData.append('session_token', userData.sessionToken);

        await axios.post('https://onepgr.com/sessions/destroy_api?onepgr_apicall=1&xhr_flag=1', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            withCredentials: true
        });

        // Clear user data
        localStorage.removeItem('user');
        localStorage.removeItem('parsedProfileAttributes');

    } catch (error) {
        console.error('Error during logout:', error);
        // Even if the API call fails, we should still clear local data
        localStorage.removeItem('user');
        localStorage.removeItem('parsedProfileAttributes');
    }
};

export default logoutUser;