import axios from "axios";
import { v4 as uuidv4 } from "uuid";
const meetSessionApi = "https://pages.onepgr.com/session";
// const meetSessionApi = "http://localhost:4200/session";

export const generateOpenAiResponse = async (prompt_msg,subject,campaignData) => {
    try {
        let body = {
            prompt: prompt_msg,
            subject: subject,
            userId: campaignData?campaignData.userId:'',
          };
          if(campaignData){
            body["campaign_id"] = campaignData.campaignId
            ? campaignData.campaignId
            : "";
            body["serial_number"] = campaignData.serialNumber
            ? campaignData.serialNumber
            : "";
            body["version_id"] = campaignData.version_id
            ? campaignData.version_id
            : "";
          }
        const response = await axios.post(
          `${meetSessionApi}/generateAiResponse`,
          body
        );
        console.log('contactsList----->',response.data)
        return response.data;
      } catch (error) {
        console.log("Error While creating New Sequence", error);
      }
  };
  export const createAndScheduleContactAction = async (actionList, emailEngine, linkTracking) => {
    try {
        const body = {
            actionBlockList : actionList,
            email_engine : emailEngine,
            advLinkTracking: linkTracking ? "1" : "0"
          }
        const response = await axios.post(
          `${meetSessionApi}/createAndSceduleContactActions`,
          body
        );
        console.log('scheduleContact------>',response.data)
        return response.data;
      } catch (error) {
        console.log("Error While createAndScheduleContactAction Sequence", error);
      }
  };

  export const createSequenceHistoryApi = async (payLoad) => {
    try {
        const response = await axios.post(
          `${meetSessionApi}/createSequenceHistory`,
          payLoad
        );
        console.log('scheduleContact------>',response.data)
        return response.data;
      } catch (error) {
        console.log("Error While createSequenceHistoryApi Sequence", error);
      }
  };